import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n'; // Importa el archivo de configuración de i18next
import StyleConfigProvider from './StyleConfigProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Router>
    <StyleConfigProvider>
      <App />
    </StyleConfigProvider>
  </Router>
);

reportWebVitals();
