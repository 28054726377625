// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      "language": "EN",
      "search": "Search...",
      "products": "Products",
      "toggleLanguage": "Toggle Language"
    }
  },
  es: {
    translation: {
      "language": "ES",
      "search": "Buscar...",
      "products": "Productos",
      "toggleLanguage": "Cambiar Idioma"
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "es", // idioma predeterminado
    keySeparator: false,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
