import React from "react";

function SinLactosaIcon({ width, color, background }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width={width || 80}
      fill={color}
      strokeWidth={0}
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
    
    <rect x="-6" width="72" fill={background} y="-6" height="72" fill-opacity="1"/>

    <path fill={color} d="M 25.953125 1.746094 C 31.890625 0.964844 37.976562 1.878906 43.265625 4.738281 C 52.445312 9.460938 58.546875 19.359375 58.519531 29.699219 C 58.652344 35.382812 56.933594 40.984375 53.824219 45.726562 C 48.703125 53.542969 39.660156 58.53125 30.300781 58.519531 C 21.539062 58.710938 12.949219 54.527344 7.558594 47.644531 C 4.265625 43.441406 2.152344 38.328125 1.605469 33.007812 C 1.277344 29.316406 1.511719 25.597656 2.585938 22.039062 C 4.359375 15.859375 8.304688 10.363281 13.558594 6.664062 C 17.246094 4.117188 21.496094 2.328125 25.953125 1.746094 Z M 25.953125 1.746094 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={background} d="M 28.328125 4.96875 C 32.941406 4.675781 37.585938 5.644531 41.675781 7.800781 C 46.195312 10.15625 49.960938 13.945312 52.289062 18.480469 C 54.355469 22.457031 55.273438 26.945312 55.082031 31.414062 C 54.800781 36.597656 52.890625 41.6875 49.609375 45.71875 C 46.90625 49.121094 43.320312 51.738281 39.304688 53.382812 C 34.492188 55.289062 29.105469 55.664062 24.070312 54.472656 C 20.011719 53.515625 16.210938 51.472656 13.117188 48.679688 C 9.09375 45.058594 6.304688 40.121094 5.308594 34.796875 C 4.636719 31.140625 4.664062 27.285156 5.644531 23.683594 C 6.917969 18.742188 9.75 14.234375 13.644531 10.9375 C 17.722656 7.425781 22.964844 5.328125 28.328125 4.96875 Z M 28.328125 4.96875 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={color} d="M 27.507812 6.5625 C 28.789062 6.53125 30.078125 6.507812 31.363281 6.484375 C 31.375 6.808594 31.378906 7.132812 31.390625 7.457031 C 30.933594 7.472656 30.480469 7.488281 30.027344 7.503906 C 30.054688 8.964844 30.085938 10.425781 30.109375 11.886719 C 29.722656 11.890625 29.339844 11.890625 28.953125 11.886719 C 28.9375 10.4375 28.910156 8.992188 28.882812 7.542969 C 28.429688 7.539062 27.976562 7.539062 27.523438 7.539062 C 27.519531 7.210938 27.511719 6.886719 27.507812 6.5625 Z M 27.507812 6.5625 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={color} d="M 33.75 7.101562 C 34.925781 6.699219 36.425781 7.234375 36.730469 8.53125 C 36.945312 9.65625 36.671875 11.019531 35.882812 11.878906 C 34.789062 13.054688 32.414062 12.394531 32.214844 10.75 C 32.019531 9.503906 32.488281 7.648438 33.75 7.101562 Z M 33.75 7.101562 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={color} d="M 25.457031 7.210938 C 25.832031 7.449219 26.160156 7.785156 26.492188 8.078125 C 26.183594 8.238281 25.875 8.398438 25.570312 8.5625 C 25.253906 8.390625 24.9375 8.238281 24.605469 8.101562 C 24.09375 8.058594 23.773438 8.539062 23.746094 9.003906 C 23.710938 9.855469 23.960938 10.875 24.605469 11.476562 C 25.601562 11.753906 25.679688 11.179688 26.171875 10.539062 C 26.53125 10.550781 26.890625 10.566406 27.253906 10.585938 C 26.992188 11.105469 26.773438 11.839844 26.261719 12.171875 C 25.46875 12.664062 24.359375 12.757812 23.652344 12.0625 C 22.808594 11.21875 22.464844 9.84375 22.65625 8.683594 C 22.859375 7.429688 24.332031 6.769531 25.457031 7.210938 Z M 25.457031 7.210938 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={background} d="M 34.011719 8.203125 C 34.441406 7.714844 35.558594 8.046875 35.628906 8.746094 C 35.738281 9.53125 35.554688 10.441406 35.097656 11.097656 C 34.625 11.734375 33.550781 11.503906 33.355469 10.753906 C 33.210938 9.925781 33.433594 8.835938 34.011719 8.203125 Z M 34.011719 8.203125 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={color} d="M 40.339844 8.917969 C 41.378906 9.285156 41.609375 10.101562 41.851562 11.074219 C 41.496094 11.023438 41.140625 10.972656 40.785156 10.921875 C 40.394531 10.082031 40.3125 9.550781 39.203125 9.859375 C 39.542969 10.816406 40.328125 11.460938 40.726562 12.363281 C 41.003906 13.523438 39.855469 14.632812 38.695312 14.191406 C 37.503906 13.855469 37.097656 12.984375 36.84375 11.863281 C 37.183594 11.863281 37.527344 11.863281 37.867188 11.863281 C 38.101562 12.339844 38.152344 13.027344 38.738281 13.199219 C 39.121094 13.382812 39.449219 13.273438 39.710938 12.867188 C 39.363281 11.9375 38.332031 11.167969 38.179688 10.199219 C 38.226562 9.046875 39.320312 8.515625 40.339844 8.917969 Z M 40.339844 8.917969 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={color} d="M 19.363281 8.953125 C 20.683594 10.285156 21.980469 11.632812 23.269531 12.984375 C 22.933594 13.164062 22.589844 13.347656 22.25 13.527344 C 21.988281 13.242188 21.726562 12.960938 21.464844 12.675781 C 21.023438 12.910156 20.578125 13.144531 20.140625 13.382812 C 20.21875 13.765625 20.300781 14.148438 20.382812 14.527344 C 20.046875 14.710938 19.710938 14.890625 19.375 15.070312 C 18.992188 13.261719 18.558594 11.457031 18.222656 9.636719 C 18.597656 9.394531 18.980469 9.175781 19.363281 8.953125 Z M 19.363281 8.953125 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={background} d="M 19.453125 10.492188 C 19.898438 10.96875 20.339844 11.449219 20.78125 11.929688 C 20.484375 12.085938 20.191406 12.242188 19.894531 12.402344 C 19.75 11.765625 19.601562 11.128906 19.453125 10.492188 Z M 19.453125 10.492188 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={color} d="M 44.078125 11.269531 C 45.007812 12.019531 45.878906 12.835938 46.777344 13.617188 C 46.558594 13.867188 46.347656 14.113281 46.132812 14.363281 C 45.507812 13.828125 44.890625 13.285156 44.273438 12.742188 C 44.039062 13.015625 43.808594 13.285156 43.574219 13.558594 C 44.023438 13.953125 44.46875 14.347656 44.914062 14.734375 C 44.699219 14.980469 44.484375 15.230469 44.269531 15.476562 C 43.816406 15.085938 43.371094 14.691406 42.921875 14.300781 C 42.628906 14.625 42.34375 14.953125 42.066406 15.285156 C 42.699219 15.839844 43.335938 16.390625 43.96875 16.949219 C 43.75 17.199219 43.53125 17.449219 43.3125 17.699219 C 42.382812 16.894531 41.464844 16.078125 40.542969 15.265625 C 41.730469 13.945312 42.863281 12.570312 44.078125 11.269531 Z M 44.078125 11.269531 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={color} d="M 14.054688 12.632812 C 15.019531 13.71875 15.988281 14.808594 16.953125 15.898438 C 17.527344 15.40625 18.070312 14.890625 18.648438 14.398438 C 18.867188 14.648438 19.089844 14.902344 19.308594 15.148438 C 18.457031 15.910156 17.605469 16.675781 16.753906 17.4375 C 15.570312 16.105469 14.390625 14.761719 13.207031 13.429688 C 13.492188 13.160156 13.773438 12.898438 14.054688 12.632812 Z M 14.054688 12.632812 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={color} d="M 28.285156 13.925781 C 30.425781 13.738281 32.589844 13.882812 34.648438 14.527344 C 38.863281 15.761719 42.46875 18.851562 44.429688 22.769531 C 46.222656 26.332031 46.664062 30.589844 45.554688 34.429688 C 44.683594 37.558594 42.828125 40.351562 40.351562 42.445312 C 35.875 46.230469 29.378906 47.285156 23.953125 45.050781 C 19.59375 43.351562 16.101562 39.613281 14.644531 35.171875 C 13.9375 33.117188 13.726562 30.96875 13.832031 28.804688 C 14.136719 24.335938 16.441406 20.027344 19.992188 17.296875 C 22.363281 15.40625 25.269531 14.246094 28.285156 13.925781 Z M 28.285156 13.925781 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={background} d="M 26.996094 16.570312 C 29.375 16.617188 31.765625 16.511719 34.144531 16.621094 C 35.152344 16.660156 35.023438 17.902344 35.015625 18.609375 C 35.050781 19.238281 34.5625 19.480469 34.21875 19.9375 C 34.164062 21.503906 34.519531 22.839844 35.152344 24.253906 C 35.824219 25.796875 36.265625 27.402344 36.253906 29.097656 C 36.253906 32.800781 36.238281 36.507812 36.222656 40.210938 C 36.226562 41.320312 35.878906 42.375 34.921875 43.011719 C 33.96875 43.59375 32.578125 43.441406 31.5 43.46875 C 29.730469 43.414062 27.9375 43.550781 26.175781 43.390625 C 24.703125 43.230469 23.796875 41.933594 23.777344 40.515625 C 23.71875 36.910156 23.757812 33.304688 23.722656 29.699219 C 23.683594 28.121094 23.882812 26.59375 24.480469 25.125 C 25.167969 23.421875 25.867188 21.839844 25.769531 19.945312 C 25.523438 19.664062 25.257812 19.398438 25.03125 19.097656 C 24.921875 18.523438 24.917969 17.785156 25.039062 17.214844 C 25.320312 16.382812 26.324219 16.640625 26.996094 16.570312 Z M 26.996094 16.570312 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={color} d="M 12.898438 16.828125 L 13.199219 17.386719 C 12.773438 18.234375 12.164062 18.976562 11.714844 19.816406 C 9.574219 23.613281 8.671875 28.058594 9.160156 32.390625 C 9.699219 37.351562 12.070312 42.070312 15.757812 45.4375 C 16.324219 45.972656 17.011719 46.390625 17.542969 46.957031 C 17.953125 47.734375 17.035156 47.511719 16.699219 47.203125 C 12.542969 44.148438 9.671875 39.304688 8.679688 34.265625 C 7.710938 29.390625 8.472656 24.210938 10.832031 19.832031 C 11.398438 18.789062 12.03125 17.644531 12.898438 16.828125 Z M 12.898438 16.828125 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={color} d="M 47.050781 16.765625 C 48.898438 18.816406 50.105469 21.523438 50.878906 24.148438 C 52.300781 29.164062 51.828125 34.667969 49.570312 39.367188 C 47.992188 42.644531 45.5625 45.75 42.460938 47.699219 C 42.355469 46.867188 42.472656 46.875 43.054688 46.363281 C 47.9375 42.550781 50.863281 36.492188 50.917969 30.304688 C 51 25.917969 49.632812 21.574219 47.121094 17.980469 C 46.75 17.457031 46.808594 17.367188 47.050781 16.765625 Z M 47.050781 16.765625 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={color} d="M 27.601562 27.269531 C 28.941406 27.390625 30.125 28.277344 31.488281 28.378906 C 32.488281 28.429688 33.339844 28.117188 34.277344 27.832031 C 34.667969 30.289062 34.441406 32.890625 34.484375 35.398438 C 34.441406 36.976562 34.5625 38.578125 34.40625 40.144531 C 34.300781 40.867188 33.726562 41.328125 33.003906 41.363281 C 31.40625 41.457031 29.800781 41.375 28.199219 41.410156 C 27.496094 41.375 26.488281 41.5 25.960938 40.9375 C 25.464844 40.46875 25.53125 39.628906 25.496094 39.003906 C 25.503906 36.304688 25.5 33.601562 25.480469 30.898438 C 25.46875 29.839844 25.535156 28.785156 25.742188 27.742188 C 26.347656 27.472656 26.914062 27.199219 27.601562 27.269531 Z M 27.601562 27.269531 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={color} d="M 39.574219 45.804688 C 39.722656 46.101562 39.871094 46.398438 40.015625 46.695312 C 39.289062 47.0625 38.546875 47.402344 37.816406 47.765625 C 37.976562 48.089844 38.140625 48.410156 38.296875 48.734375 C 38.832031 48.472656 39.367188 48.207031 39.898438 47.945312 C 40.039062 48.242188 40.183594 48.539062 40.324219 48.835938 C 39.792969 49.09375 39.261719 49.351562 38.734375 49.609375 C 38.910156 50.007812 39.097656 50.398438 39.292969 50.789062 C 40.054688 50.421875 40.8125 50.054688 41.566406 49.683594 C 41.71875 49.976562 41.863281 50.273438 42.015625 50.570312 C 40.910156 51.117188 39.804688 51.652344 38.699219 52.195312 C 37.917969 50.585938 37.132812 48.976562 36.339844 47.371094 C 37.417969 46.855469 38.496094 46.324219 39.574219 45.804688 Z M 39.574219 45.804688 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={color} d="M 20.308594 46.027344 C 21.347656 46.53125 22.390625 47.035156 23.429688 47.535156 C 23.292969 47.835938 23.152344 48.136719 23.011719 48.433594 C 22.3125 48.105469 21.617188 47.761719 20.917969 47.425781 C 20.757812 47.746094 20.59375 48.070312 20.433594 48.390625 C 20.953125 48.640625 21.476562 48.890625 22 49.144531 C 21.859375 49.445312 21.722656 49.742188 21.582031 50.039062 C 21.058594 49.789062 20.53125 49.535156 20.007812 49.285156 C 19.675781 49.972656 19.339844 50.65625 19.007812 51.34375 C 18.664062 51.183594 18.316406 51.019531 17.972656 50.851562 C 18.75 49.246094 19.527344 47.632812 20.308594 46.027344 Z M 20.308594 46.027344 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={color} d="M 25.03125 48.035156 C 25.933594 48.171875 26.878906 48.242188 27.742188 48.53125 C 28.625 48.855469 28.851562 49.867188 28.53125 50.683594 C 28.273438 51.03125 27.902344 51.3125 27.582031 51.601562 C 27.824219 52.382812 28.078125 53.164062 28.324219 53.949219 C 27.90625 53.890625 27.484375 53.832031 27.066406 53.773438 C 26.726562 52.777344 26.746094 51.824219 25.761719 51.210938 C 25.609375 51.976562 25.476562 52.742188 25.355469 53.511719 C 24.980469 53.449219 24.605469 53.390625 24.230469 53.332031 C 24.496094 51.566406 24.761719 49.800781 25.03125 48.035156 Z M 25.03125 48.035156 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={color} d="M 34.492188 48.003906 C 34.546875 48.328125 34.597656 48.65625 34.652344 48.984375 C 33.847656 49.113281 33.035156 49.234375 32.230469 49.378906 C 32.28125 49.726562 32.339844 50.074219 32.398438 50.425781 C 32.988281 50.335938 33.574219 50.242188 34.167969 50.152344 L 34.316406 51.121094 C 33.730469 51.21875 33.140625 51.3125 32.550781 51.40625 C 32.617188 51.835938 32.683594 52.261719 32.753906 52.6875 C 33.589844 52.566406 34.425781 52.421875 35.261719 52.296875 C 35.3125 52.621094 35.359375 52.953125 35.410156 53.28125 C 34.199219 53.476562 32.988281 53.664062 31.777344 53.863281 C 31.503906 52.09375 31.214844 50.332031 30.941406 48.5625 C 32.125 48.375 33.304688 48.1875 34.492188 48.003906 Z M 34.492188 48.003906 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={background} d="M 26.035156 49.089844 C 26.476562 49.214844 26.941406 49.3125 27.363281 49.492188 C 27.585938 49.867188 27.507812 50.140625 27.332031 50.511719 C 26.835938 50.53125 26.335938 50.546875 25.835938 50.550781 C 25.898438 50.0625 25.964844 49.574219 26.035156 49.089844 Z M 26.035156 49.089844 " fill-opacity="1" fill-rule="nonzero"/>
    
    </svg>
  );
}

export default SinLactosaIcon;
