import styled from 'styled-components';

export const ProductWrapper = styled.div`
    display: flex;
    padding-right: 0;
    border: ${({ style }) => style?.ProductWrapper?.border || ''};
    padding: ${({ style }) => style?.ProductWrapper?.padding || ''};
    border-radius: ${({ style }) => style?.ProductWrapper?.borderRadius || ''};
    margin: ${({ style }) => style?.ProductWrapper?.margin || ''};

`;

export const ProductImage = styled.img`
    width: ${({ style }) => style?.ProductImage?.width || ''};
    height: ${({ style }) => style?.ProductImage?.height || ''};
    border-radius: ${({ style }) => style?.ProductImage?.borderRadius || ''};
`;

export const ProductInfo = styled.div`
    padding-left: ${({ style }) => style?.ProductInfo?.paddingLeft || '15px'};
    display: ${({ style }) => style?.ProductInfo?.display || 'flex'};
    flex-direction: ${({ style }) => style?.ProductInfo?.flexDirection || 'column'};
`;

export const ProductTitle = styled.h3`
    margin: ${({ style }) => style?.ProductTitle?.margin || '0'};
    font-size: ${({ style }) => style?.ProductTitle?.fontSize || '1.1rem'};
    font-weight: ${({ style }) => style?.ProductTitle?.fontWeight || 'bold'};
    color: ${({ style }) => style?.ProductTitle?.color || 'black'};
    @media (max-width: 360px) {
        font-size: ${({ style }) => (style?.ProductTitle?.fontSize-0.2) || '1.5rem'};
    }
`;

export const ProductDescription = styled.p`
    margin: ${({ style }) => style?.ProductDescription?.margin || '10px 0'};
    font-size: ${({ style }) => style?.ProductDescription?.fontSize || '1.2rem'};
    color: ${({ style }) => style?.ProductDescription?.color || 'black'};
    @media (max-width: 360px) {
        font-size: ${({ style }) => (style?.ProductDescription?.fontSize-0.2) || '0.8rem'};
    }
`;

export const ProductPrice = styled.span`
    font-size: ${({ style }) => style?.ProductPrice?.fontSize || '1.2rem'};
    color: ${({ style }) => style?.ProductPrice?.color || 'black'};
    font-weight: ${({ style }) => style?.ProductPrice?.fontWeight || 'bold'};

    @media (max-width: 360px) {
        font-size: ${({ style }) => (style?.ProductPrice?.fontSize-0.2) || '0.8rem'};
    }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const IconImage = styled.img`
  width: 50px; 
  height: 50x;
  margin-left: 1px; 
fill: ${({ style }) => (style?.color) || 'white'};
`;