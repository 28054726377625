// SidebarStyles.js
import styled from 'styled-components';

export const SidebarContainer = styled.div`
    width: ${props => props.width}px;
    padding: 20px;
    background-color: #f9f9f9;
    border-left: 1px solid #ccc;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    overflow-y: auto;
    font-size:12px;
    color: black;
    @media (max-width: 1024px) {
        display: none;
    }
`;

export const SectionTitle = styled.h2`
    font-size: 1.5em;
    margin-bottom: 10px;
`;

export const Label = styled.label`
    display: block;
    margin: 10px 0 5px;
    font-weight: 500;
    font-size: 12px !important;;
`;

export const Input = styled.input`
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 0.85em !important;
`;

export const SectionHeader = styled.h3`
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 1.4em !important;
    font-weight: bold;
`;

export const SubSectionHeader = styled.h4`
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 14px !important;
    font-weight: bold;
`;

export const SliderContainer = styled.div`
    margin-top: 20px;
`;

export const SliderInput = styled.input`
    width: 100%;
`;

export const NestedContainer = styled.div`
    margin-left: 20px;
    padding-left: 10px;
    border-left: 2px solid #ccc;
`;

export const ExportButton = styled.button`
    display: block;
    margin: 20px 0;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background-color: #45a049;
    }
`;

export const AddButton = styled.button`
    display: block;
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background-color: #45a049;
    }
`;

export const FormContainer = styled.form`
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
`;

export const CollapsibleHeader = styled(SubSectionHeader)`
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;
