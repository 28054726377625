import styled from 'styled-components';

export const StyledTableContainer = styled.div`
  margin-top: 1rem;
  background-color:  ${(props) => props.style ? props.style.background_color : 'black'};
`;

export const StyledTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledTitle = styled.h3`
  font-family: ${(props) => props.style ? props.style.title.font_family : 'Arial'};
  font-size: ${(props) => props.style ? props.style.title.font_size : '25px'};
  color: ${(props) => props.style ? props.style.title.font_color : 'black'};
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color:  ${(props) => props.style ? props.style.background_color : 'black'};
  margin-bottom: 50px;
`;

export const StyledTableRow = styled.tr`
  border-bottom: none;
  background-color: ${(props) => props.style ? props.style.background_color : 'white'};
`;

export const StyledTableCell = styled.td`
  font-family: ${(props) => props.style ? props.style.tableBody.font_family : 'Arial'};
  font-size: ${(props) => props.style ? props.style.tableBody.font_size : '16px'};
  color: ${(props) => props.style ? props.style.tableBody.font_color : 'black'};
  background-color: ${(props) => props.style ? props.style.background_color : 'red'};
`;

export const StyledCheckbox = styled.input`
  width: 10px;
  max-width: 10px;
`;

export const StyledRequiredLabel = styled.strong`
  font-family: ${(props) => props.style ? props.style.font_family : 'Arial'};
  font-size: ${(props) => props.style ? props.style.font_size : '16px'};
  color: ${(props) => props.style ? props.style.font_color : 'black'};
`;
