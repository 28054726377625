import React from 'react';
import PropTypes from 'prop-types';

const Price = ({ amount }) => {
  let numericAmount = amount;

  if (typeof amount === 'string') {
    numericAmount = parseFloat(amount.replace(/,/g, ''));
  }

  if (isNaN(numericAmount)) {
    return <span>Invalid number</span>;
  }

  const formattedAmount = numericAmount.toLocaleString('es-CL', { 
    minimumFractionDigits: 0, 
    maximumFractionDigits: 2 
  });

  return (
    <span>${formattedAmount}</span>
  );
};

Price.propTypes = {
  amount: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
};

export default Price;
