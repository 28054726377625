import React, { useState, useEffect, useRef } from 'react';
import {
    SidebarContainer,
    SectionTitle,
    Label,
    Input,
    SectionHeader,
    SubSectionHeader,
    SliderContainer,
    SliderInput,
    NestedContainer,
    ExportButton,
    AddButton,
    FormContainer
} from './styles/SidebarStyles';
import { v4 as uuidv4 } from 'uuid';
import { SketchPicker } from 'react-color';

const Sidebar = ({ styleConfig, menuData, setStyleConfig, setMenuData, setIsUpdateArray }) => {
    const [title, setTitle] = useState('');
    const [style, setStyle] = useState({});
    const [menu, setMenu] = useState({ sections: [], products: [] });
    const [width, setWidth] = useState(500); // initial width
    const [collapsedSections, setCollapsedSections] = useState({});
    const [colorPickerVisible, setColorPickerVisible] = useState({});
    const colorPickerRef = useRef({});
    const [newSection, setNewSection] = useState({ name: '', order: '', is_modifier: false, section_image: '' });
    const [newProduct, setNewProduct] = useState({ name: '', order: '', section: '', price: '', description: '', images: '' });

    useEffect(() => {
        if (menuData && menuData.metadata) {
            setTitle(menuData.metadata.title);
            setStyle(menuData.metadata.style);
            setMenu(menuData.metadata.menu);
            initializeCollapsedSections(menuData.metadata.menu);
        }

        const handleClickOutside = (event) => {
            Object.keys(colorPickerRef.current).forEach(key => {
                if (colorPickerRef.current[key] && !colorPickerRef.current[key].contains(event.target)) {
                    setColorPickerVisible(prev => ({ ...prev, [key]: false }));
                }
            });
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuData]);

    const initializeCollapsedSections = (menu) => {
        const collapsed = {};
        const initialize = (obj, keys = []) => {
            Object.keys(obj).forEach((key) => {
                const nestedKeys = [...keys, key];
                collapsed[nestedKeys.join('.')] = true;
                if (typeof obj[key] === 'object' && obj[key] !== null) {
                    initialize(obj[key], nestedKeys);
                }
            });
        };
        initialize(menu);
        setCollapsedSections(collapsed);
    };

    const handleTitleChange = (e) => {
        const newTitle = e.target.value;
        setTitle(newTitle);
        const updatedMenuData = {
            ...menuData,
            metadata: {
                ...menuData.metadata,
                title: newTitle
            }
        };
        setMenuData(updatedMenuData);
    };

    const deepCopy = (obj) => {
        return JSON.parse(JSON.stringify(obj));
    };

    const handleStyleChange = (e, keys, colorPicker = false) => {
        const value = colorPicker ? e.hex : e.target.value;
        const updatedStyle = deepCopy(style);
        keys.reduce((acc, key, index) => {
            if (index === keys.length - 1) {
                acc[key] = value;
            } else {
                acc[key] = acc[key] || {};
            }
            return acc[key];
        }, updatedStyle);
        setStyle(updatedStyle);
        const updatedMenuData = {
            ...menuData,
            metadata: {
                ...menuData.metadata,
                style: updatedStyle
            }
        };
        setStyleConfig(updatedStyle);
        setMenuData(updatedMenuData);
    };

    const handleMenuChange = (e, keys) => {
        const updatedMenu = deepCopy(menu);
        keys.reduce((acc, key, index) => {
            if (index === keys.length - 1) {
                acc[key] = e.target.value;
            } else {
                acc[key] = acc[key] || {};
            }
            return acc[key];
        }, updatedMenu);
        setMenu(updatedMenu);
        const updatedMenuData = {
            ...menuData,
            metadata: {
                ...menuData.metadata,
                menu: updatedMenu
            }
        };
        setMenuData(updatedMenuData);
    };

    const toggleSection = (sectionKey) => {
        setCollapsedSections((prevState) => ({
            ...prevState,
            [sectionKey]: !prevState[sectionKey],
        }));
    };

    const toggleColorPicker = (sectionKey) => {
        setColorPickerVisible((prevState) => ({
            ...prevState,
            [sectionKey]: !prevState[sectionKey],
        }));
    };

    const renderNestedInputs = (obj, handleChange, keys = []) => {
        return Object.keys(obj).map((key) => {
            const nestedKeys = [...keys, key];
            const sectionKey = nestedKeys.join('.');

            if (typeof obj[key] === 'object' && obj[key] !== null) {
                const isCollapsed = collapsedSections[sectionKey];

                return (
                    <NestedContainer key={sectionKey}>
                        <SubSectionHeader onClick={() => toggleSection(sectionKey)}>
                            {key} {isCollapsed ? '▼' : '▲'}
                        </SubSectionHeader>
                        {!isCollapsed && renderNestedInputs(obj[key], handleChange, nestedKeys)}
                    </NestedContainer>
                );
            }

            if (['font_color', 'icons_color', 'background_color', 'color'].includes(key)) {
                return (
                    <div key={sectionKey} ref={el => (colorPickerRef.current[sectionKey] = el)}>
                        <Label>{key}</Label>
                        <Input
                            type="text"
                            name={key}
                            value={obj[key]}
                            onChange={(e) => handleChange(e, nestedKeys)}
                            onClick={() => toggleColorPicker(sectionKey)}
                        />
                        {colorPickerVisible[sectionKey] && (
                            <SketchPicker
                                color={obj[key]}
                                onChangeComplete={(color) => handleChange(color, nestedKeys, true)}
                            />
                        )}
                    </div>
                );
            }

            return (
                <div key={sectionKey}>
                    <Label>{key}</Label>
                    <Input
                        type="text"
                        name={key}
                        value={obj[key]}
                        onChange={(e) => handleChange(e, nestedKeys)}
                        required
                    />
                </div>
            );
        });
    };

    const handleWidthChange = (e) => {
        setWidth(e.target.value);
    };

    const handleExportJSON = () => {
        const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(menuData));
        const downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href", dataStr);
        downloadAnchorNode.setAttribute("download", "menuData.json");
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    };

    const handleNewSectionChange = (e) => {
        const { name, value } = e.target;
        setNewSection({ ...newSection, [name]: value });
    };

    const handleAddSection = (e) => {
        e.preventDefault();
        if (newSection.name && newSection.order) {
            const updatedMenu = { ...menu, sections: [...menu.sections, newSection] };
            setMenu(updatedMenu);
            const updatedMenuData = {
                ...menuData,
                metadata: {
                    ...menuData.metadata,
                    menu: updatedMenu
                }
            };
            setMenuData(updatedMenuData);
            setNewSection({ name: '', order: '', is_modifier: false, section_image: '' });
            setIsUpdateArray(updatedMenuData);
        }
    };

    const handleNewProductChange = (e) => {
        const { name, value } = e.target;
        setNewProduct({ ...newProduct, [name]: value });
    };

    const handleAddProduct = (e) => {
        e.preventDefault();
        if (newProduct.name && newProduct.order && newProduct.section && newProduct.price) {
            newProduct.modifiers = [];
            newProduct.isModifier = false;
            const newUuid = uuidv4();
            newProduct.toteat_id = newUuid;
            newProduct.order = parseInt(newProduct.order);
            newProduct.images = [newProduct.images];
            const updatedMenu = { ...menu, products: [...menu.products, newProduct] };
            setMenu(updatedMenu);
            const updatedMenuData = {
                ...menuData,
                metadata: {
                    ...menuData.metadata,
                    menu: updatedMenu
                }
            };
            setMenuData(updatedMenuData);
            setNewProduct({ name: '', order: '', section: '', price: '', description: '', images: '' });
            setIsUpdateArray(updatedMenuData);
        }
    };

    if (!menuData || !menuData.metadata) {
        return null;
    }

    return (
        <SidebarContainer width={width}>
            <SectionTitle>Edit Menu Data</SectionTitle>
            <SliderContainer>
                <Label>Sidebar Width</Label>
                <SliderInput type="range" min="400" max="1200" value={width} onChange={handleWidthChange} />
            </SliderContainer>
            <div>
                <Label>Title</Label>
                <Input type="text" value={title} onChange={handleTitleChange} />
            </div>
            <div>
                <SectionHeader>Style</SectionHeader>
                {renderNestedInputs(style, handleStyleChange)}
            </div>
            <ExportButton onClick={handleExportJSON}>Export JSON</ExportButton>
        </SidebarContainer>
    );
};

export default Sidebar;
