import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Header from './components/Header';
import StickyMenu from './components/StickyMenu';
import Loading from './components/Loading';
import { filterProducts } from './utils/filterProducts';
import { handleScroll } from './utils/handleScroll';
import { getSections } from './utils/getSections';
import GlobalStyles from './GlobalStyles';
import { fetchData } from './utils/fetchData';
import Sections from './components/Sections';
import Sidebar from './components/Sidebar';

function App() {
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [isSticky, setIsSticky] = useState(false);
    const [styleConfig, setStyleConfig] = useState(null);
    const [menuData, setMenuData] = useState(null);
    const [mainJson, setJsonMain] = useState(null);
    const [inputSearchText, setInputSearchText] = useState("");
    const [selectedSection, setSelectedSection] = useState(null);
    const [isUpdateArray, setIsUpdateArray] = useState({});
    const [currentSection, setCurrentSection] = useState('');
    const location = useLocation();
    const showSidebar = new URLSearchParams(location.search).get('showSidebar') === 'true';
    useEffect(() => {
        const handleScroll = () => {
          const sections = document.querySelectorAll('section');
          let foundSection = '';
          sections.forEach(section => {
            const rect = section.getBoundingClientRect();
            if (rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2) {
              foundSection = section.id;
            }
          });
          setCurrentSection(foundSection);
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
    useEffect(() => {
        const onScroll = () => handleScroll(setIsSticky);
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, []);

    useEffect(() => {
        fetchData(true, setFilteredProducts, setStyleConfig, setMenuData, setJsonMain, mainJson);
    }, []);

    const sections = menuData ? getSections(menuData) : [];
    const productsModifiers = sections.filter(section=> section.is_modifier);
    const filteredSections = sections.filter(section =>
        filteredProducts.some(product => product.section_id === section.section_id && !section.is_modifier)
    );

    // Crear refs de manera incondicional
    const sectionRefs = useRef({});

    useEffect(() => {
        if (filteredSections.length > 0) {
            const newSectionRefs = filteredSections.reduce((acc, section) => {
                acc[section.name] = React.createRef();
                return acc;
            }, {});
            sectionRefs.current = newSectionRefs;
        }
    }, [filteredSections]);

    const handleSearch = (searchTerm) => {
        if (menuData) {
            let products=  menuData.products.filter(product => !product.is_modifier)
            const filtered = filterProducts(products, searchTerm);
            setFilteredProducts(filtered);
            setInputSearchText(searchTerm);
        }
    };

    if (!styleConfig || !menuData) {
        return <Loading />;
    }

    const { front_page_img, banner, restaurant_logo } = styleConfig;



    return (
        <>
            <GlobalStyles styleConfig={styleConfig} />
            <div className="container">
                <div id="header">
                    <Header
                        onSearch={handleSearch}
                        sections={filteredSections}
                        frontPageImg={front_page_img}
                        banner={banner}
                        restaurantLogo={restaurant_logo}
                        styleConfig={styleConfig}
                        setInputSearchText={setInputSearchText}
                        inputSearchText={inputSearchText}
                        selectedSection={selectedSection}
                        setSelectedSection={setSelectedSection}
                    />
                </div>
                {isSticky && <StickyMenu 
                    sections={filteredSections} 
                    onSearch={handleSearch} 
                    styleConfig={styleConfig} 
                    setInputSearchText={setInputSearchText}
                    inputSearchText={inputSearchText}
                    selectedSection={selectedSection}
                    setSelectedSection={setSelectedSection}
                    sectionRefs={sectionRefs}
                    currentSection={currentSection}
                />}
                {styleConfig && <Sections 
                    sections={filteredSections} 
                    filteredProducts={filteredProducts} 
                    styleConfig={styleConfig}
                    sectionRefs={sectionRefs}
                    modifiers={productsModifiers}
                />}
                {showSidebar && (
                    <Sidebar
                        styleConfig={styleConfig}
                        setStyleConfig={setStyleConfig}
                        menuData={mainJson}
                        setMenuData={setJsonMain}
                        setIsUpdateArray={setIsUpdateArray}
                    />
                )}
            </div>
        </>
    );
}

export default App;
