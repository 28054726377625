import React from 'react';
import PicanteIcon from './icons/PicanteIcon';
import OrganicoIcon from './icons/OrganicoIcon';
import SinGlutenIcon from './icons/SinGlutenIcon';
import SinLactosaIcon from './icons/SinLactosaIcon';
import SinAzucarIcon from '../components/icons/sinAzucarIcon';
import VeganoIcon from './icons/VeganoIcon';
const tagComponents = {
  picante: PicanteIcon,
  organico: OrganicoIcon,
  sin_gluten: SinGlutenIcon,
  sin_lactosa: SinLactosaIcon,
  sin_azucar: SinAzucarIcon,
  vegano:VeganoIcon
};

const TagIcons = ({ tags, style }) => {
  const defaultColor = "none";
  const defaultBackground = "none";
  return (
    <>
      {tags && tags.map((tag) => {
        const TagComponent = tagComponents[tag];
        return TagComponent ? (
          <TagComponent 
            key={tag} 
            width={50} 
            color={style?.color || defaultColor} 
            background={style?.background || defaultBackground} 
          />
        ) : null;
      })}
    </>
  );
};

export default TagIcons;
