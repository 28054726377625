import styled from 'styled-components';
import { BiSearch , BiChevronLeft, BiChevronRight} from 'react-icons/bi';


export const ButtonIcon = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: ${props => props.size || '24px'};
  color: ${props => props.color || 'inherit'};
  &:hover {
    opacity: 0.8;
  }
  &:focus {
    outline: none;
  }
`;
export const BackgroundOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3); // Ajusta el color y la opacidad según tus necesidades
  z-index: -1; // Asegúrate de que el fondo esté detrás del contenido
`;

// Estilos para las flechas
export const ArrowLeft = styled(BiChevronLeft)`
  position: absolute;
  left: 0px;
  top: 40%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 24px;
  color: ${(props) => props.styleConfig ? props.styleConfig.menu.icons_color : 'white'};
  width: 30px;
  height: 50px;
  background:${(props) => props.styleConfig ? props.styleConfig.menu.background_color : ''};
`;

export const ArrowRight = styled(BiChevronRight)`
  position: absolute;
  right: 0px;
  top: 40%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 24px;
  color: ${(props) => props.styleConfig ? props.styleConfig.menu.icons_color : 'white'};
  width: 30px;
  height: 50px;
  background:${(props) => props.styleConfig ? props.styleConfig.menu.background_color : ''};
`;

export const HeaderImage = styled.img`
  background-size: cover;
  background-position: center;
  width: 100%;
  ${(props) => props.styleConfig};
  margin: ${({ styleConfig }) => styleConfig?.marging || ''};
  border-radius: 5px;
`;

export const SearchInputWrapper = styled.div`
  position: relative;
  width: 100%;
  font-size: 16px;
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 5px 5px 5px 30px;
  margin-left: 10px;
  border-radius: 20px;
  border: 1px solid #ccc;
  &:focus {
    box-shadow: none;
    border-color: #ccc;
  }
`;

export const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 0;
  color: ${(props) => props.styleConfig ? props.styleConfig.menu.icons_color : 'white'};
`;

export const SearchIcon = styled(BiSearch)`
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
`;

export const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  overflow-x: auto;
  white-space: nowrap;
  padding: 15px 0;
  background-color: ${(props) => props.styleConfig ? props.styleConfig.menu.background_color : ''};
  font-family: ${(props) => props.styleConfig ? props.styleConfig.menu.font : 'sans-serif'};
  font-size: ${(props) => props.styleConfig ? props.styleConfig.menu.font_size : 16}px;
  color: ${(props) => props.styleConfig ? props.styleConfig.menu.font_color : ''};
  padding-left: ${(props) => props.hasLeftArrow ? '35px' : '0px'};
  padding-right: ${(props) => props.hasRightArrow ? '35px' : '0px'};
`;

export const StickyHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: ${(props) => props.styleConfig ? props.styleConfig.menu.background_color : 'white'};
  color: ${(props) => props.styleConfig ? props.styleConfig.menu.font_color : 'black'};
`;

export const MenuItem = styled.div.attrs((props) => ({
  isSelected: props.isSelected,
}))`
  padding: 10px;
  cursor: pointer;
  border-bottom: ${(props) => props.isSelected ? '2px solid' : 'none'};
  border-bottom-color: ${(props) => props.styleConfig.menu.isSelected || 'black'};
  color: ${(props) => props.isSelected ? props.styleConfig.menu.colorIsSelected || 'black' : 'inherit'};
`;

