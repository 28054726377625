import React from "react";

function OrganicoIcon({ width, color, background }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width={width || 80}
      fill={color}
      strokeWidth={0}
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
    
    <rect x="-6" width="72" fill={background} y="-6" height="72" fill-opacity="1"/>
    <path fill={color} d="M 25.953125 1.746094 C 28.707031 1.390625 31.507812 1.398438 34.261719 1.777344 C 38.96875 2.4375 43.503906 4.441406 47.296875 7.289062 C 54.304688 12.605469 58.628906 21.183594 58.519531 30 C 58.597656 35.65625 56.832031 41.207031 53.707031 45.902344 C 48.566406 53.617188 39.589844 58.527344 30.300781 58.519531 C 21.464844 58.714844 12.808594 54.453125 7.421875 47.472656 C 3.597656 42.5 1.390625 36.292969 1.476562 30 C 1.40625 25.476562 2.566406 20.941406 4.621094 16.921875 C 8.769531 8.804688 16.914062 2.972656 25.953125 1.746094 Z M 25.953125 1.746094 " fill-opacity="1" fill-rule="nonzero"/>
    <path fill={background} d="M 26.257812 5.03125 C 31.929688 4.195312 37.851562 5.320312 42.820312 8.171875 C 46.339844 10.195312 49.4375 13.050781 51.648438 16.457031 C 54.960938 21.679688 56.210938 28.121094 55.1875 34.210938 C 54.515625 38.089844 53.015625 41.8125 50.691406 44.996094 C 47.746094 49.039062 43.636719 52.207031 38.9375 53.933594 C 34.585938 55.566406 29.769531 55.972656 25.207031 55.089844 C 19.921875 54.109375 15 51.390625 11.34375 47.457031 C 8.347656 44.292969 6.253906 40.363281 5.214844 36.140625 C 3.078125 27.691406 5.554688 18.234375 11.820312 12.117188 C 15.691406 8.320312 20.882812 5.769531 26.257812 5.03125 Z M 26.257812 5.03125 " fill-opacity="1" fill-rule="nonzero"/>
    <path fill={color} d="M 29.949219 9.125 C 32.203125 11.199219 33.296875 14.378906 32.644531 17.394531 C 32.269531 19.261719 31.246094 20.777344 29.945312 22.128906 C 28.410156 20.582031 27.316406 18.710938 27.117188 16.507812 C 26.804688 13.738281 27.925781 11.011719 29.949219 9.125 Z M 29.949219 9.125 " fill-opacity="1" fill-rule="nonzero"/>
    <path fill={color} d="M 20.515625 17.492188 C 23.515625 17.296875 26.507812 18.835938 28.183594 21.3125 C 29.289062 22.914062 29.695312 24.761719 29.699219 26.683594 C 28.457031 26.648438 27.253906 26.566406 26.097656 26.078125 C 22.621094 24.78125 20.253906 21.214844 20.515625 17.492188 Z M 20.515625 17.492188 " fill-opacity="1" fill-rule="nonzero"/>
    <path fill={color} d="M 39.4375 17.449219 C 39.414062 19.320312 39.058594 21.121094 37.996094 22.6875 C 36.457031 25.101562 33.835938 26.515625 31 26.699219 C 31.132812 27.773438 31.28125 28.847656 31.417969 29.925781 C 31.019531 29.925781 30.617188 29.925781 30.21875 29.925781 C 30.226562 28.546875 30.1875 27.167969 30.226562 25.789062 C 30.320312 23.160156 31.695312 20.65625 33.835938 19.128906 C 35.46875 17.917969 37.421875 17.4375 39.4375 17.449219 Z M 39.4375 17.449219 " fill-opacity="1" fill-rule="nonzero"/>
    <path fill={color} d="M 18.269531 32.742188 C 18.292969 35.390625 18.277344 38.039062 18.28125 40.6875 C 17.5625 40.6875 16.84375 40.6875 16.125 40.6875 C 16.132812 38.722656 16.125 36.765625 16.132812 34.804688 C 15.6875 34.914062 15.242188 35.023438 14.800781 35.132812 C 14.660156 34.570312 14.523438 34.007812 14.386719 33.445312 C 15.679688 33.019531 16.894531 32.691406 18.269531 32.742188 Z M 18.269531 32.742188 " fill-opacity="1" fill-rule="nonzero"/>
    <path fill={color} d="M 22.035156 32.875 C 23.375 32.441406 24.902344 32.777344 25.820312 33.875 C 27.171875 35.488281 27.148438 38.25 25.691406 39.789062 C 24.472656 41.113281 22.199219 41.210938 20.851562 40.039062 C 18.707031 38.132812 19.132812 33.820312 22.035156 32.875 Z M 22.035156 32.875 " fill-opacity="1" fill-rule="nonzero"/>
    <path fill={color} d="M 30.152344 32.910156 C 31.339844 32.488281 32.71875 32.667969 33.679688 33.515625 C 35.371094 35.011719 35.460938 38.015625 33.996094 39.691406 C 32.867188 41.011719 30.769531 41.222656 29.351562 40.257812 C 26.921875 38.539062 27.214844 33.953125 30.152344 32.910156 Z M 30.152344 32.910156 " fill-opacity="1" fill-rule="nonzero"/>
    <path fill={color} d="M 37.054688 32.902344 C 37.734375 32.625 38.566406 32.679688 39.160156 33.144531 C 40.171875 33.949219 40.210938 35.679688 39.191406 36.503906 C 38.367188 37.195312 37.027344 37.097656 36.347656 36.25 C 35.515625 35.195312 35.792969 33.480469 37.054688 32.902344 Z M 37.054688 32.902344 " fill-opacity="1" fill-rule="nonzero"/>
    <path fill={color} d="M 42.835938 32.871094 C 43.335938 32.847656 43.839844 32.828125 44.34375 32.820312 C 42.34375 35.460938 40.292969 38.0625 38.273438 40.6875 C 37.765625 40.6875 37.257812 40.6875 36.75 40.6875 C 38.785156 38.089844 40.757812 35.4375 42.835938 32.871094 Z M 42.835938 32.871094 " fill-opacity="1" fill-rule="nonzero"/>
    <path fill={background} d="M 37.613281 33.996094 C 38.796875 33.699219 38.90625 35.664062 37.804688 35.796875 C 37.117188 35.324219 37.054688 34.601562 37.613281 33.996094 Z M 37.613281 33.996094 " fill-opacity="1" fill-rule="nonzero"/>
    <path fill={background} d="M 22.339844 34.945312 C 22.996094 34.339844 23.902344 34.648438 24.289062 35.386719 C 24.746094 36.296875 24.746094 37.667969 24.089844 38.488281 C 23.503906 39.160156 22.488281 39.019531 22.0625 38.25 C 21.546875 37.332031 21.535156 35.710938 22.339844 34.945312 Z M 22.339844 34.945312 " fill-opacity="1" fill-rule="nonzero"/>
    <path fill={background} d="M 30.664062 34.847656 C 31.390625 34.347656 32.261719 34.753906 32.574219 35.515625 C 32.953125 36.429688 32.949219 37.777344 32.253906 38.546875 C 31.621094 39.191406 30.617188 38.953125 30.234375 38.164062 C 29.757812 37.210938 29.757812 35.558594 30.664062 34.847656 Z M 30.664062 34.847656 " fill-opacity="1" fill-rule="nonzero"/>
    <path fill={color} d="M 42.414062 36.722656 C 43.105469 36.460938 43.964844 36.5625 44.523438 37.066406 C 45.445312 37.886719 45.433594 39.507812 44.507812 40.320312 C 43.738281 41.015625 42.414062 40.964844 41.710938 40.191406 C 40.738281 39.160156 41.042969 37.246094 42.414062 36.722656 Z M 42.414062 36.722656 " fill-opacity="1" fill-rule="nonzero"/>
    <path fill={background} d="M 42.972656 37.859375 C 44.003906 37.542969 44.152344 39.441406 43.195312 39.621094 C 42.390625 39.460938 42.273438 38.152344 42.972656 37.859375 Z M 42.972656 37.859375 " fill-opacity="1" fill-rule="nonzero"/>
    <path fill={color} d="M 16.316406 43.023438 C 17.253906 42.617188 18.417969 42.820312 19.082031 43.621094 C 19.867188 44.523438 19.746094 45.964844 18.820312 46.722656 C 17.757812 47.621094 16.007812 47.390625 15.289062 46.179688 C 14.609375 45.039062 15.09375 43.535156 16.316406 43.023438 Z M 16.316406 43.023438 " fill-opacity="1" fill-rule="nonzero"/>
    <path fill={color} d="M 20.210938 42.898438 C 21.222656 42.964844 22.761719 42.660156 23.589844 43.3125 C 24.421875 44.257812 23.984375 45.054688 23.214844 45.820312 C 23.539062 46.273438 23.859375 46.726562 24.179688 47.179688 C 23.734375 47.179688 23.285156 47.175781 22.839844 47.167969 C 22.433594 46.625 22.003906 46.117188 21.496094 45.667969 C 21.457031 46.171875 21.421875 46.675781 21.394531 47.179688 C 21 47.179688 20.605469 47.179688 20.214844 47.179688 C 20.214844 45.753906 20.214844 44.324219 20.210938 42.898438 Z M 20.210938 42.898438 " fill-opacity="1" fill-rule="nonzero"/>
    <path fill={color} d="M 28.578125 43.316406 C 28.367188 43.605469 28.15625 43.890625 27.941406 44.175781 C 27.472656 44.09375 26.859375 43.765625 26.398438 43.964844 C 25.480469 44.335938 25.464844 45.820312 26.433594 46.171875 C 27.148438 46.371094 27.367188 46.191406 27.792969 45.628906 C 27.429688 45.597656 27.0625 45.570312 26.699219 45.539062 C 26.699219 45.261719 26.699219 44.980469 26.699219 44.699219 C 27.363281 44.699219 28.027344 44.699219 28.6875 44.699219 C 28.6875 45.382812 28.683594 46.058594 28.675781 46.738281 C 27.632812 47.171875 26.710938 47.554688 25.621094 46.976562 C 24.160156 46.21875 24.121094 44.078125 25.480469 43.195312 C 26.554688 42.523438 27.511719 42.890625 28.578125 43.316406 Z M 28.578125 43.316406 " fill-opacity="1" fill-rule="nonzero"/>
    <path fill={color} d="M 30.851562 42.898438 C 31.226562 42.871094 31.601562 42.847656 31.976562 42.832031 C 32.585938 44.28125 33.199219 45.726562 33.816406 47.175781 C 33.394531 47.179688 32.972656 47.179688 32.554688 47.183594 C 32.4375 46.933594 32.320312 46.675781 32.203125 46.421875 C 31.664062 46.425781 31.121094 46.425781 30.578125 46.425781 C 30.46875 46.675781 30.359375 46.933594 30.25 47.183594 C 29.839844 47.179688 29.425781 47.179688 29.011719 47.179688 C 29.632812 45.753906 30.1875 44.296875 30.851562 42.898438 Z M 30.851562 42.898438 " fill-opacity="1" fill-rule="nonzero"/>
    <path fill={color} d="M 34.234375 42.898438 C 34.605469 42.898438 34.972656 42.898438 35.34375 42.898438 C 35.9375 43.65625 36.53125 44.414062 37.121094 45.175781 C 37.121094 44.417969 37.117188 43.660156 37.117188 42.898438 C 37.507812 42.898438 37.898438 42.898438 38.289062 42.898438 C 38.285156 44.328125 38.289062 45.757812 38.285156 47.179688 C 37.933594 47.179688 37.582031 47.179688 37.226562 47.175781 C 36.621094 46.394531 36.011719 45.617188 35.402344 44.839844 C 35.398438 45.617188 35.398438 46.398438 35.398438 47.179688 C 35.011719 47.179688 34.621094 47.179688 34.234375 47.179688 C 34.234375 45.753906 34.238281 44.328125 34.234375 42.898438 Z M 34.234375 42.898438 " fill-opacity="1" fill-rule="nonzero"/>
    <path fill={color} d="M 39.074219 42.898438 C 39.472656 42.898438 39.875 42.898438 40.273438 42.898438 C 40.273438 44.328125 40.277344 45.757812 40.273438 47.1875 C 39.875 47.1875 39.472656 47.1875 39.074219 47.1875 C 39.074219 45.757812 39.074219 44.328125 39.074219 42.898438 Z M 39.074219 42.898438 " fill-opacity="1" fill-rule="nonzero"/>
    <path fill={color} d="M 42.886719 42.835938 C 43.796875 42.6875 44.371094 43.15625 45.074219 43.613281 C 44.796875 43.84375 44.515625 44.074219 44.234375 44.304688 C 43.804688 44.152344 43.339844 43.914062 42.882812 43.90625 C 41.972656 44.242188 41.867188 45.652344 42.730469 46.082031 C 43.210938 46.363281 43.816406 45.898438 44.277344 45.757812 C 44.554688 45.972656 44.824219 46.183594 45.101562 46.398438 C 44.5 46.789062 43.964844 47.285156 43.203125 47.273438 C 42.035156 47.3125 41.039062 46.476562 40.921875 45.304688 C 40.785156 44.078125 41.652344 42.957031 42.886719 42.835938 Z M 42.886719 42.835938 " fill-opacity="1" fill-rule="nonzero"/>
    <path fill={background} d="M 16.941406 43.945312 C 17.671875 43.726562 18.367188 44.234375 18.386719 45.003906 C 18.4375 45.816406 17.644531 46.429688 16.882812 46.117188 C 15.949219 45.71875 15.964844 44.277344 16.941406 43.945312 Z M 16.941406 43.945312 " fill-opacity="1" fill-rule="nonzero"/>
    <path fill={background} d="M 21.492188 43.890625 C 22.160156 43.992188 22.984375 43.75 22.855469 44.734375 C 22.378906 44.890625 21.871094 44.96875 21.371094 45.070312 C 21.398438 44.675781 21.441406 44.28125 21.492188 43.890625 Z M 21.492188 43.890625 " fill-opacity="1" fill-rule="nonzero"/>
    <path fill={background} d="M 31.402344 44.296875 C 31.558594 44.691406 31.714844 45.085938 31.871094 45.476562 C 31.558594 45.480469 31.242188 45.484375 30.925781 45.484375 C 31.085938 45.089844 31.246094 44.695312 31.402344 44.296875 Z M 31.402344 44.296875 " fill-opacity="1" fill-rule="nonzero"/>
    </svg>
  );
}

export default OrganicoIcon;
