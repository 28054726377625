import React from "react";

function PicanteIcon({ width, color, background }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width={width || 80}
      fill={color}
      strokeWidth={0}
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
    
    <rect x="-6" width="72" fill={background} y="-6" height="72" fill-opacity="1"/>
    <path fill={color} d="M 25.953125 1.746094 C 28.390625 1.429688 30.867188 1.398438 33.308594 1.660156 C 37.984375 2.132812 42.390625 3.910156 46.261719 6.535156 C 53.777344 11.714844 58.535156 20.550781 58.519531 29.699219 C 58.648438 35.160156 57.0625 40.558594 54.175781 45.175781 C 49.128906 53.300781 39.882812 58.53125 30.300781 58.519531 C 21.316406 58.714844 12.523438 54.3125 7.160156 47.128906 C 3.496094 42.210938 1.394531 36.148438 1.476562 30 C 1.40625 25.550781 2.53125 21.089844 4.519531 17.121094 C 8.632812 8.902344 16.839844 2.984375 25.953125 1.746094 Z M 25.953125 1.746094 " fill-opacity="1" fill-rule="nonzero"/>
    <path fill={background} d="M 26.257812 4.753906 C 32.890625 3.769531 39.988281 5.480469 45.339844 9.550781 C 49.472656 12.671875 52.648438 17.027344 54.238281 21.964844 C 57.003906 30.128906 55.265625 39.542969 49.777344 46.183594 C 46.015625 50.785156 40.644531 54.007812 34.789062 55.089844 C 26.011719 56.832031 16.617188 53.601562 10.765625 46.824219 C 6.835938 42.382812 4.609375 36.515625 4.46875 30.597656 C 4.351562 25.996094 5.523438 21.386719 7.777344 17.378906 C 9.78125 13.851562 12.667969 10.777344 16.066406 8.566406 C 19.109375 6.558594 22.664062 5.296875 26.257812 4.753906 Z M 26.257812 4.753906 " fill-opacity="1" fill-rule="nonzero"/>
    <path fill={color} d="M 31.652344 9.578125 C 31.023438 11.46875 30.765625 13.386719 31.480469 15.296875 C 31.9375 16.597656 32.800781 17.507812 33.660156 18.542969 C 35.332031 20.601562 36.382812 23.113281 36.117188 25.804688 C 36.003906 27.023438 35.527344 28.101562 34.988281 29.183594 C 36.160156 28.148438 37.238281 27.066406 37.988281 25.675781 C 38.925781 24.046875 39.238281 22.25 39.148438 20.386719 C 42.070312 23.179688 44.058594 26.894531 44.65625 30.902344 C 45.160156 33.910156 44.878906 36.953125 44.058594 39.882812 C 43.414062 42.398438 42.097656 44.714844 40.15625 46.453125 C 37.859375 48.5625 34.835938 49.714844 31.789062 50.1875 C 29.339844 50.605469 26.691406 50.203125 24.320312 49.523438 C 21.71875 48.78125 19.152344 47.359375 17.683594 45.015625 C 15.949219 42.105469 15.175781 38.523438 15.789062 35.175781 C 16.207031 32.867188 17.304688 30.835938 18.730469 29 C 18.128906 31.851562 18.128906 34.714844 20.371094 36.863281 C 21.640625 35.710938 22.691406 34.480469 22.894531 32.710938 C 23.113281 30.902344 22.050781 29.699219 21.835938 27.910156 C 21.394531 24.886719 22.429688 21.839844 24.792969 19.863281 C 24.792969 20.957031 24.753906 22.128906 25.070312 23.183594 C 25.375 24.15625 25.96875 24.871094 26.382812 25.792969 C 26.824219 26.773438 26.988281 27.777344 27.1875 28.816406 C 28.132812 27.109375 28.859375 25.394531 28.816406 23.398438 C 28.816406 21.238281 27.410156 19.601562 27.371094 17.410156 C 27.140625 14.507812 28.695312 11.992188 30.886719 10.222656 C 31.140625 10.003906 31.398438 9.792969 31.652344 9.578125 Z M 31.652344 9.578125 " fill-opacity="1" fill-rule="nonzero"/>
    <path fill={background} d="M 41.453125 35.765625 C 41.914062 35.515625 42.160156 35.59375 42.179688 36.007812 C 41.96875 37.390625 41.105469 38.257812 40.164062 39.195312 C 40.199219 39.871094 40.339844 40.621094 40.09375 41.265625 C 39.839844 41.988281 39.3125 42.46875 38.710938 42.90625 C 36.789062 44.257812 34.746094 45.308594 32.398438 45.664062 C 29.503906 46.105469 26.488281 45.898438 23.632812 45.328125 C 21.824219 44.878906 19.898438 44.371094 19.015625 42.550781 C 21.890625 42.933594 24.804688 42.945312 27.667969 42.511719 C 30.070312 42.1875 32.359375 41.53125 34.242188 39.945312 C 35.300781 39.140625 36.125 37.917969 37.285156 37.28125 C 38.328125 36.769531 39.144531 37.664062 39.835938 38.3125 C 40.566406 37.558594 40.984375 36.683594 41.453125 35.765625 Z M 41.453125 35.765625 " fill-opacity="1" fill-rule="nonzero"/>
    </svg>
  );
}

export default PicanteIcon;
