import styled from 'styled-components';

export const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

export const ModalContent = styled.div`
    background: ${(props) => props.style ? props.style.background_color : 'black'};
    border-radius: 10px;
    overflow: hidden;
    max-width: 600px;
    width: 95%;
    font-family: ${(props) => props.style ? props.style.font : 'sans-serif'};
    max-height: 800px;
    overflow-y: auto;  
    @media (max-width: 430px) {
       margin-top: ${(props) => props.style ? props.style.marginTop : '5px'};
    }
    margin-top: 10px;
`;

export const ModalImage = styled.img`
    width: 100%;
    object-fit: cover;
`;

export const ModalInfo = styled.div`
    padding: 20px;
    max-height: 350px;
    overflow-y: auto;
`;

export const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
`;

export const ModalTitle = styled.h3`
    font-weight: bold;
    font-family: ${(props) => props.style ? props.style.font : 'sans-serif'};
    color: ${(props) => props.style ? props.style.font_color : 'white'};
    font-size: ${(props) => props.style ? props.style.font_size : 16}px;
`;

export const ModalDescription = styled.p`
    font-family: ${(props) => props.style ? props.style.font : 'sans-serif'};
    color: ${(props) => props.style ? props.style.font_color : 'white'};
    font-size: ${(props) => props.style ? props.style.font_size : 16}px;
`;

export const ModalPrice = styled.h4`
    font-family: ${(props) => props.style ? props.style.font : 'sans-serif'};
    color: ${(props) => props.style ? props.style.font_color: ''};
    font-size: ${(props) => props.style ? props.style.font_size : 16}px;
`;

export const HeartButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    color: ${(props) => props.style ? props.style.icons_color : 'white'};
`;

export const TagIconsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const Table = styled.table`
  width: 100%;
  background-color: ${(props) => props.style ? props.style.background_color : 'white'};
  border-collapse: collapse;
  margin: ${(props) => props.style ? props.style.margin : '0'};
`;

export const TableHeader = styled.th`
  font-family: ${(props) => props.style ? props.style.title.font_family : 'Arial'};
  font-size: ${(props) => props.style ? props.style.title.font_size : '16px'};
  color: ${(props) => props.style ? props.style.title.font_color : 'black'};
  background-color: ${(props) => props.style ? props.style.background_color : '#F1EEE5'};
  padding: 10px;
`;

export const TableBody = styled.td`
  font-family: ${(props) => props.style ? props.style.tableBody.font_family : 'Arial'};
  font-size: ${(props) => props.style ? props.style.tableBody.font_size : '16px'};
  color: ${(props) => props.style ? props.style.tableBody.font_color : 'black'};
  background-color: ${(props) => props.style ? props.style.background_color : '#F1EEE5'};
  padding: 10px;
  border: 1px solid #ccc;
`;

export const CloseButton = styled.button`
  position: absolute;
  background: none;
  border: none;
  cursor: pointer;
  color: ${(props) => props.style ? props.style.icons_color_close : 'black'};
  font-size: 24px;
  z-index: 10;
  padding: 5px;
  transition: color 0.3s ease, transform 0.3s ease;

  &:hover {
    color: #ccc; 
    transform: scale(1.1);
  }

  @media (max-width: 767px) {
    font-size: 20px; // Versión móvil
    right: 10px;
  }

  @media (min-width: 768px) {
    font-size: 24px; // Versión escritorio
    right: 10px;
    top: 10px;
  }

  @media (min-width: 431px) {
    top: 10px;
  }
`;

export const CloseFooterButton = styled.button`
  color: ${(props) => props.style ? props.style.tableBody.font_color : 'black'};
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 16px;
  background-color: ${(props) => props.style ? props.style.background_color : '#F1EEE5'};
  &:hover {
    background: #ccc; 
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px;
`;
