import React, { useRef, useEffect, useState } from 'react';
import { StickyMenuWrapper, SearchInputWrapper, SearchInput, IconButton, SearchIcon, Menu, MenuItem } from './styles/StickyMenuStyles';
import { BiHeart, BiShoppingBag } from 'react-icons/bi';
import { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import { ArrowLeft, ArrowRight } from './styles/HeaderStyles';

function StickyMenu({ sections, onSearch, inputSearchText, setInputSearchText, styleConfig, selectedSection, setSelectedSection, currentSection }) {
  const { t } = useTranslation();
  const stickyMenuRef = useRef(null);
  const menuContainerRef = useRef(null);
  const [menuHeight, setMenuHeight] = useState(0);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  useEffect(() => {
    if (stickyMenuRef.current) {
      setMenuHeight(stickyMenuRef.current.offsetHeight);
    }
  }, []);

  useEffect(() => {
    const scrollToSection = (sectionName) => {
      const selectedElement = document.getElementById(`menu-item-${sectionName}`);
      if (selectedElement && menuContainerRef.current) {
        const elementLeft = selectedElement.offsetLeft;
        const elementWidth = selectedElement.offsetWidth;
        const containerWidth = menuContainerRef.current.offsetWidth;

        const scrollPosition = elementLeft - containerWidth / 2 + elementWidth / 2;
        menuContainerRef.current.scrollTo({
          left: scrollPosition,
          behavior: 'smooth',
        });
      }
    };

    if (currentSection) {
      scrollToSection(currentSection);
    }
    if (selectedSection) {
      scrollToSection(selectedSection);
    }
  }, [currentSection, selectedSection]);

  useEffect(() => {
    const menuElement = menuContainerRef.current;
    const handleScroll = () => {
      const scrollLeft = menuElement.scrollLeft;
      const scrollWidth = menuElement.scrollWidth;
      const clientWidth = menuElement.clientWidth;

      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
    };

    menuElement.addEventListener('scroll', handleScroll);
    handleScroll(); // Check initial state

    return () => {
      menuElement.removeEventListener('scroll', handleScroll);
    };
  }, [sections]);

  const handleInputChange = (e) => {
    onSearch(e.target.value);
    setInputSearchText(e.target.value);
  };

  const handleSectionClick = (sectionName) => {
    setSelectedSection(sectionName); // Actualizamos el estado con la sección seleccionada
  };

  const scrollMenu = (direction) => {
    const menuElement = menuContainerRef.current;
    const scrollAmount = 200; // Cantidad de desplazamiento
    menuElement.scrollLeft += direction === 'left' ? -scrollAmount : scrollAmount;
  };

  return (
    <StickyMenuWrapper ref={stickyMenuRef} styleConfig={styleConfig}>
      <div className="sticky-menu-content">
        <div className="row align-items-center mt-1" style={{ marginRight: "7px", marginLeft: "3px" }}>
          <div className="col-9" style={{ paddingLeft: 0, paddingRight: 0 }}>
            <SearchInputWrapper>
              <SearchIcon />
              <SearchInput
                type="text"
                placeholder={t('search')}
                className="form-control rounded-pill pl-5"
                onChange={handleInputChange}
                value={inputSearchText}
              />
            </SearchInputWrapper>
          </div>
          <div className="col-1" style={{ paddingLeft: 0, paddingRight: 0 }}>
              <IconButton className="btn" styleConfig={styleConfig}>
                <BiHeart size={30} />
              </IconButton>
          </div>
          <div className="col-1" style={{ paddingLeft: 0, paddingRight: 0 }}>
              <IconButton className="btn" styleConfig={styleConfig}>
                <BiShoppingBag size={30} />
              </IconButton>
          </div>
        </div>
        <div style={{ position: 'relative' }}>
          {showLeftArrow && (
              <ArrowLeft styleConfig={styleConfig} onClick={() => scrollMenu('left')} />
          )}
          {showRightArrow && (
              <ArrowRight styleConfig={styleConfig} onClick={() => scrollMenu('right')} />
          )}
          <Menu ref={menuContainerRef} styleConfig={styleConfig} style={{ marginRight: "7px" }}>
            {sections.map((section, index) => (
              <Link
                key={`${section.name}-${index}`}
                to={section.name}
                smooth={true}
                duration={500}
                offset={-menuHeight}
                onClick={() => handleSectionClick(section.name)} // Manejador de clic para actualizar la sección seleccionada
              >
                <MenuItem id={`menu-item-${section.name}`} isSelected={selectedSection === section.name || currentSection === section.name} styleConfig={styleConfig}>
                  {section.name}
                </MenuItem>
              </Link>
            ))}
          </Menu>
        </div>
      </div>
    </StickyMenuWrapper>
  );
}

export default StickyMenu;
