import fetchStyleConfig from "../styleConfig";
import updateFavicon from "./updateFavicon";

export const fetchData = async (
  is_request,
  setFilteredProducts,
  setStyleConfig,
  setMenuData,
  setJsonMain,
  mainJson
) => {
  if (is_request) {
    await new Promise((resolve) => setTimeout(resolve, 3000)); // Agregamos un sleep de 3 segundos
    const response = await fetchStyleConfig();
    document.title = response.metadata.title;
    setStyleConfig(response.metadata.style);
    setMenuData(response.metadata.menu);
    setJsonMain(response);

    const favicon = response.metadata.style.favicon;
    if (favicon) {
      updateFavicon(favicon);
    }

    const groupedModifiers = {};
    response.metadata.menu.products.forEach((product) => {
      if (product.isModifier) {
        if (!groupedModifiers[product.section_id]) {
          groupedModifiers[product.section_id] = [];
        }
        groupedModifiers[product.section_id].push(product);
      }
    });
    const productsWithModifiers = response.metadata.menu.products
      .filter((product) => !product.is_modifier)
      .map((product) => {
        const newModifiers = product.modifiers.map((modifier) => {
          if (groupedModifiers[modifier.section_id]) {
            return {
              ...modifier,
              items: groupedModifiers[modifier.section_id],
            };
          }
          return modifier;
        });
        return {
          ...product,
          modifiers: newModifiers,
        };
      });

    setFilteredProducts(
      productsWithModifiers.filter((product) => !product.is_modifier)
    );
  } else {
    if (mainJson) {
      const groupedModifiers = {};
      mainJson.metadata.menu.products.forEach((product) => {
        if (product.isModifier) {
          if (!groupedModifiers[product.section]) {
            groupedModifiers[product.section] = [];
          }
          groupedModifiers[product.section].push(product);
        }
      });

      const productsWithModifiers = mainJson.metadata.menu.products
        .filter((product) => !product.is_modifier)
        .map((product) => {
          const newModifiers = product.modifiers.map((modifier) => {
            if (groupedModifiers[modifier.section]) {
              return {
                ...modifier,
                items: groupedModifiers[modifier.section],
              };
            }
            return modifier;
          });
          return {
            ...product,
            modifiers: newModifiers,
          };
        });
      debugger
      setFilteredProducts(
        productsWithModifiers.filter((product) => !product.is_modifier)
      );
    }
  }
};
