import React from 'react';
import { HeaderImageProduct, TitleHeader, PlaceholderImage, ImageWithTitle } from './styles/AppStyles';
import Product from './Product';

const Section = ({ section, filteredProducts, modifiers, styleConfig }) => {
    // Encontrar la primera imagen de producto que coincida con la sección
    const productWithImage = filteredProducts.find(
        product => product.section_id === section.section_id && product.images.length > 0
    );
    const firstProductImage = productWithImage ? productWithImage.images[0] : '';
    const sectionImage = section.section_image || firstProductImage;
    console.info(`section_name: ${section.name} sectionImage: ${sectionImage} firstProductImage: ${firstProductImage}`);
    return (
        <section id={section.name} className="container" style={{ paddingLeft: 0, paddingRight: 0 }}>
            {/* Título de la sección si hay imagen y tiene título */}
            {section.section_image!=="" && (
                <HeaderImageProduct src={section.section_image} />
            )}


            {/* Imagen de cabecera si hay imagen y no tiene título */}
            {section.section_image ==="" && firstProductImage !=="" && (
                <ImageWithTitle styleConfig={styleConfig}>
                    <div className="image-container">
                        <img src={firstProductImage} alt={section.name} />
                        <div className="background-overlay" />
                    </div>  
                    <div className="title-overlay">
                        {section.name}
                    </div>
                </ImageWithTitle>
            )}

            {/* Imagen de cabecera sin imagen y sin título */}
            {section.section_image === "" && firstProductImage ==="" && (
                <PlaceholderImage styleConfig={styleConfig}>
                    {section.name}
                </PlaceholderImage>
            )}

            {/* Renderizado de productos filtrados */}
            {filteredProducts
                .filter(product => product.section_id === section.section_id)
                .map(product => (
                    <Product
                        key={`${product.toteat_id}-${product.order}`}
                        images={product.images}
                        title={product.name}
                        description={product.description}
                        price={product.price}
                        variantStyle={product.variant_style}
                        style={styleConfig}
                        tags={product.tags}
                        productModifiers={product.modifiers}
                        modifiers={modifiers}
                    />
                ))}
        </section>
    );
};

export default Section;
