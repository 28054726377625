import React from "react";

function SinLactosaIcon({ width, color, background }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width={width || 80}
      fill={color}
      strokeWidth={0}
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
    
    <rect x="-6" width="72" fill={background} y="-6" height="72" fill-opacity="1"/>

    <path fill={color} d="M 25.953125 1.746094 C 31.300781 1.066406 36.742188 1.667969 41.652344 3.953125 C 47.003906 6.320312 51.519531 10.363281 54.523438 15.382812 C 59.128906 23.035156 59.835938 32.804688 56.328125 41.023438 C 54.535156 45.304688 51.734375 49.089844 48.140625 52.027344 C 44.035156 55.453125 38.882812 57.628906 33.589844 58.324219 C 29.082031 58.855469 24.511719 58.40625 20.25 56.8125 C 15.582031 55.15625 11.445312 52.21875 8.222656 48.464844 C 4.621094 44.199219 2.269531 38.863281 1.636719 33.308594 C 1.222656 29.210938 1.542969 25.0625 2.863281 21.148438 C 4.761719 15.253906 8.65625 10.058594 13.738281 6.535156 C 17.398438 4.054688 21.550781 2.320312 25.953125 1.746094 Z M 25.953125 1.746094 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={background} d="M 26.257812 4.753906 C 32.773438 3.789062 39.675781 5.410156 44.996094 9.3125 C 49.09375 12.304688 52.273438 16.515625 53.96875 21.300781 C 55.699219 26.039062 55.96875 31.269531 54.722656 36.160156 C 53.761719 40.003906 51.894531 43.609375 49.304688 46.609375 C 45.6875 50.929688 40.46875 53.914062 34.960938 55.015625 C 30.757812 55.894531 26.289062 55.554688 22.203125 54.292969 C 17.3125 52.773438 12.964844 49.703125 9.824219 45.671875 C 6.292969 41.175781 4.398438 35.410156 4.464844 29.703125 C 4.492188 26.0625 5.339844 22.46875 6.859375 19.164062 C 8.851562 14.867188 12.101562 11.152344 16.066406 8.566406 C 19.109375 6.558594 22.660156 5.296875 26.257812 4.753906 Z M 26.257812 4.753906 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={color} d="M 33.527344 7.113281 C 38.554688 7.882812 43.3125 10.398438 46.765625 14.132812 C 49.378906 16.90625 51.257812 20.339844 52.25 24.015625 C 52.480469 24.621094 51.992188 25.144531 51.40625 24.769531 C 51.070312 24.167969 50.90625 23.457031 50.683594 22.808594 C 48.835938 17.257812 44.644531 12.601562 39.367188 10.09375 C 36.171875 8.550781 32.644531 7.839844 29.101562 7.976562 C 24.179688 8.128906 19.363281 10.03125 15.628906 13.234375 C 12.679688 15.738281 10.429688 19.007812 9.15625 22.660156 C 8.921875 23.335938 8.777344 24.054688 8.496094 24.710938 C 7.96875 25.183594 7.375 24.664062 7.585938 24.03125 C 9.292969 17.050781 14.6875 11.070312 21.367188 8.464844 C 25.191406 6.9375 29.464844 6.46875 33.527344 7.113281 Z M 33.527344 7.113281 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={color} d="M 19.503906 14.503906 C 23.464844 14.296875 27.316406 16.027344 29.933594 18.988281 C 32.664062 15.949219 36.613281 14.15625 40.726562 14.554688 C 41.816406 15.878906 42.441406 17.683594 42.800781 19.351562 C 43.777344 24.144531 41.8125 29.277344 37.964844 32.269531 C 35.578125 34.164062 32.617188 35.101562 29.578125 35.078125 C 29.011719 35.988281 28.5625 37.015625 27.875 37.835938 C 27.433594 37.933594 27.027344 37.734375 27.078125 37.230469 C 27.386719 36.4375 27.894531 35.71875 28.316406 34.980469 C 24.496094 34.414062 21.058594 32.296875 18.960938 29.039062 C 16.320312 25.027344 16.105469 19.667969 18.480469 15.484375 C 18.757812 15.097656 18.925781 14.460938 19.503906 14.503906 Z M 19.503906 14.503906 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={background} d="M 20.375 15.609375 C 21.949219 15.710938 23.296875 16.015625 24.769531 16.574219 C 24.375 18.109375 23.980469 19.644531 23.585938 21.179688 C 22.515625 19.324219 21.433594 17.472656 20.375 15.609375 Z M 20.375 15.609375 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={background} d="M 40.21875 16.496094 C 39.363281 18.304688 38.214844 19.992188 37.261719 21.757812 C 38.519531 21.449219 39.761719 20.957031 41.042969 20.773438 C 41.644531 20.984375 41.65625 21.628906 41.050781 21.847656 C 39.535156 22.351562 37.96875 22.703125 36.4375 23.167969 C 35.320312 25.117188 34.1875 27.054688 33.078125 29.007812 C 34.761719 28.554688 36.433594 28.0625 38.113281 27.601562 C 38.621094 27.523438 39.140625 27.214844 39.304688 27.925781 C 39.375 28.566406 38.769531 28.53125 38.332031 28.726562 C 36.304688 29.289062 34.273438 29.84375 32.246094 30.421875 C 31.734375 31.28125 31.300781 32.292969 30.605469 33.019531 C 30.238281 32.972656 29.988281 32.777344 29.851562 32.433594 C 30.246094 31.527344 30.800781 30.691406 31.28125 29.828125 C 30.71875 27.589844 30.0625 25.355469 29.585938 23.101562 C 29.371094 22.480469 30.386719 22.183594 30.59375 22.777344 C 31.171875 24.640625 31.570312 26.566406 32.085938 28.449219 C 33.214844 26.496094 34.347656 24.542969 35.464844 22.585938 C 35.097656 20.972656 34.546875 19.378906 34.285156 17.746094 C 34.289062 17.160156 35.070312 17.050781 35.257812 17.492188 C 35.726562 18.679688 35.921875 19.972656 36.273438 21.199219 C 37.238281 19.574219 38.128906 17.902344 39.121094 16.300781 C 39.453125 15.703125 40.117188 15.835938 40.21875 16.496094 Z M 40.21875 16.496094 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={background} d="M 19.375 16.183594 C 20.464844 18.035156 21.523438 19.902344 22.601562 21.757812 C 21.078125 21.324219 19.550781 20.902344 18.023438 20.476562 C 18.265625 18.910156 18.679688 17.601562 19.375 16.183594 Z M 19.375 16.183594 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={background} d="M 25.828125 17.140625 C 27.136719 17.835938 28.203125 18.808594 29.203125 19.886719 C 27.773438 21.988281 26.980469 24.058594 26.707031 26.601562 C 25.945312 25.257812 25.164062 23.925781 24.398438 22.585938 C 24.898438 20.773438 25.292969 18.933594 25.828125 17.140625 Z M 25.828125 17.140625 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={background} d="M 17.957031 21.667969 C 19.789062 22.09375 21.582031 22.6875 23.402344 23.152344 C 24.535156 25.101562 25.660156 27.050781 26.78125 29.007812 C 24.195312 28.285156 21.609375 27.558594 19.023438 26.839844 C 18.339844 25.1875 17.929688 23.464844 17.957031 21.667969 Z M 17.957031 21.667969 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={color} d="M 7.355469 28.699219 C 8.492188 28.15625 9.859375 29.03125 9.796875 30.300781 C 9.765625 31.550781 8.371094 32.359375 7.273438 31.730469 C 6.058594 31.070312 6.117188 29.300781 7.355469 28.699219 Z M 7.355469 28.699219 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={background} d="M 19.777344 28.246094 C 22.160156 28.886719 24.535156 29.566406 26.914062 30.230469 C 27.203125 31.484375 27.640625 32.660156 28.171875 33.832031 C 25.085938 33.261719 22.273438 31.675781 20.40625 29.128906 C 20.191406 28.835938 19.984375 28.542969 19.777344 28.246094 Z M 19.777344 28.246094 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={color} d="M 51.136719 28.65625 C 51.96875 28.332031 52.941406 28.699219 53.3125 29.515625 C 53.722656 30.390625 53.332031 31.402344 52.464844 31.800781 C 51.582031 32.21875 50.503906 31.738281 50.171875 30.839844 C 49.855469 29.980469 50.277344 28.988281 51.136719 28.65625 Z M 51.136719 28.65625 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={color} d="M 8.335938 35.074219 C 8.683594 35.253906 8.726562 35.820312 8.867188 36.15625 C 10.171875 40.726562 13.058594 44.8125 16.886719 47.617188 C 20.660156 50.410156 25.308594 51.921875 30 51.910156 C 34.675781 51.871094 39.296875 50.339844 43.039062 47.539062 C 46.542969 44.953125 49.234375 41.308594 50.667969 37.195312 C 50.902344 36.519531 51.058594 35.796875 51.363281 35.144531 C 51.839844 34.726562 52.46875 35.179688 52.296875 35.703125 C 50.648438 42.425781 45.675781 48.257812 39.34375 51.035156 C 34.625 53.171875 29.148438 53.625 24.140625 52.320312 C 16.105469 50.320312 9.511719 43.738281 7.566406 35.6875 C 7.371094 35.304688 8.054688 34.675781 8.335938 35.074219 Z M 8.335938 35.074219 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={color} d="M 31.957031 40.832031 C 31.660156 41.117188 31.371094 41.40625 31.082031 41.695312 C 30.390625 41.371094 29.8125 40.972656 29.03125 41.316406 C 28.066406 41.765625 27.984375 43.183594 28.765625 43.832031 C 29.242188 44.21875 29.898438 44.082031 30.46875 44.070312 C 30.472656 43.566406 30.480469 43.066406 30.484375 42.5625 C 30.925781 42.5625 31.363281 42.5625 31.796875 42.5625 C 31.800781 43.289062 31.804688 44.019531 31.808594 44.746094 C 30.5625 45.351562 29.253906 45.664062 27.984375 44.921875 C 26.480469 44.027344 26.335938 41.675781 27.710938 40.605469 C 29.023438 39.605469 30.714844 39.835938 31.957031 40.832031 Z M 31.957031 40.832031 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={color} d="M 42.039062 40.078125 C 42.511719 40.082031 42.988281 40.082031 43.460938 40.078125 C 43.464844 41.808594 43.464844 43.53125 43.460938 45.261719 C 43.066406 45.265625 42.671875 45.265625 42.277344 45.265625 C 41.503906 44.347656 40.742188 43.417969 39.976562 42.496094 C 39.980469 43.417969 39.976562 44.34375 39.980469 45.265625 C 39.5 45.261719 39.019531 45.257812 38.539062 45.261719 C 38.542969 43.535156 38.539062 41.8125 38.542969 40.085938 C 38.941406 40.082031 39.34375 40.078125 39.742188 40.074219 C 40.511719 40.988281 41.269531 41.910156 42.035156 42.824219 C 42.035156 41.910156 42.039062 40.996094 42.039062 40.078125 Z M 42.039062 40.078125 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={color} d="M 15.875 40.078125 C 16.402344 40.082031 16.933594 40.082031 17.460938 40.078125 C 17.925781 41.207031 18.394531 42.328125 18.863281 43.457031 C 19.34375 42.328125 19.824219 41.203125 20.308594 40.078125 C 20.789062 40.078125 21.269531 40.078125 21.753906 40.082031 C 21.019531 41.8125 20.273438 43.539062 19.53125 45.265625 C 19.046875 45.261719 18.5625 45.257812 18.082031 45.261719 C 17.347656 43.53125 16.601562 41.8125 15.875 40.078125 Z M 15.875 40.078125 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={color} d="M 22.042969 40.082031 C 23.410156 40.078125 24.773438 40.078125 26.140625 40.082031 C 26.140625 40.460938 26.140625 40.84375 26.140625 41.21875 C 25.265625 41.21875 24.390625 41.222656 23.515625 41.21875 L 23.523438 42.074219 C 24.28125 42.074219 25.039062 42.074219 25.800781 42.074219 C 25.800781 42.4375 25.800781 42.800781 25.800781 43.160156 C 25.039062 43.164062 24.277344 43.160156 23.519531 43.164062 C 23.519531 43.488281 23.519531 43.8125 23.519531 44.136719 C 24.421875 44.132812 25.320312 44.140625 26.21875 44.140625 C 26.21875 44.515625 26.21875 44.886719 26.21875 45.261719 C 24.828125 45.265625 23.433594 45.265625 22.039062 45.261719 C 22.039062 43.535156 22.039062 41.808594 22.042969 40.082031 Z M 22.042969 40.082031 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={color} d="M 34.386719 40.078125 C 34.867188 40.082031 35.351562 40.078125 35.835938 40.078125 C 36.601562 41.804688 37.367188 43.53125 38.128906 45.261719 C 37.621094 45.261719 37.113281 45.261719 36.605469 45.265625 C 36.46875 44.929688 36.324219 44.59375 36.183594 44.257812 C 35.460938 44.257812 34.738281 44.257812 34.015625 44.257812 C 33.871094 44.59375 33.726562 44.929688 33.585938 45.265625 C 33.085938 45.261719 32.59375 45.261719 32.09375 45.261719 C 32.859375 43.535156 33.613281 41.804688 34.386719 40.078125 Z M 34.386719 40.078125 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={background} d="M 35.054688 41.722656 L 35.113281 41.59375 C 35.332031 42.117188 35.546875 42.640625 35.761719 43.160156 C 35.3125 43.15625 34.863281 43.15625 34.414062 43.15625 C 34.632812 42.679688 34.839844 42.199219 35.054688 41.722656 Z M 35.054688 41.722656 " fill-opacity="1" fill-rule="nonzero"/>
    
    </svg>
  );
}

export default SinLactosaIcon;
