import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ProgressBar } from 'react-bootstrap';
import styled from 'styled-components';

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: white; /* Fondo blanco */
  color: black; /* Letras negras */
  text-align: center;
`;

const LoadingText = styled.h2`
  margin-bottom: 20px;
  color: black; /* Letras negras */
`;

const LoadingLogo = styled.img`
  margin-top: 20px;
`;

const ProgressWrapper = styled.div`
  width: 80%;

  .progress-bar {
    background-color: #05183C; /* Color de la barra de progreso */
  }
`;

function Loading() {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(prev => {
        if (prev < 100) {
          return prev + 10;
        } else {
          clearInterval(interval);
          return 100;
        }
      });
    }, 40); // Aproximadamente 3000ms / 100 = 30ms por incremento
    return () => clearInterval(interval);
  }, []);

  return (
    <LoadingWrapper>
      <LoadingText>Cargando Menú</LoadingText>
      <ProgressWrapper>
        <ProgressBar animated now={progress} />
      </ProgressWrapper>
      <LoadingLogo src="img/powered_by_cheetrack.png" alt="Powered by Cheetrack" />
    </LoadingWrapper>
  );
}

export default Loading;
