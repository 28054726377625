import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body {
    font-family: ${(props) => props.styleConfig ? props.styleConfig.font : 'sans-serif'}, sans-serif;
    font-size:16px;
    color: ${(props) => props.styleConfig ? props.styleConfig.font_color : ''};
    background-color: ${(props) => props.styleConfig ? props.styleConfig.background_color : ''};
  }

  button, a {
    color: ${(props) => props.styleConfig ? props.styleConfig.icons_color : ''};
  }

  /* Estilos específicos para resoluciones de escritorio */
  @media only screen and (min-width: 1024px) {
    .container {
      width: 412px; /* Ancho del iPhone 14 Pro en puntos */
      margin: 10px auto; /* Centra el contenido horizontalmente */
    }
  }
`;

export default GlobalStyles;
