import React from "react";

function SinAzucarIcon({ width, color, background }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width={width || 80}
      fill={color}
      strokeWidth={0}
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
      <rect
        x="-6"
        width="72"
        fill={background}
        y="-6"
        height="72"
        fill-opacity="1"
      />

      <path
        fill={color}
        d="M 26.277344 1.769531 C 29.070312 1.40625 31.90625 1.414062 34.691406 1.796875 C 39.460938 2.464844 44.046875 4.5 47.886719 7.382812 C 54.980469 12.761719 59.363281 21.449219 59.253906 30.375 C 59.328125 36.105469 57.542969 41.722656 54.378906 46.476562 C 49.175781 54.289062 40.082031 59.257812 30.679688 59.25 C 21.734375 59.445312 12.96875 55.136719 7.515625 48.066406 C 3.640625 43.03125 1.410156 36.746094 1.496094 30.375 C 1.425781 25.796875 2.601562 21.203125 4.679688 17.132812 C 8.878906 8.914062 17.125 3.011719 26.277344 1.769531 Z M 26.277344 1.769531 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={background}
        d="M 25.972656 5.074219 C 32 4.027344 38.386719 5.113281 43.625 8.316406 C 47.109375 10.359375 50.070312 13.320312 52.238281 16.714844 C 54.988281 21.039062 56.335938 26.171875 56.199219 31.289062 C 56.003906 36.730469 54.058594 42.144531 50.652344 46.402344 C 48.269531 49.421875 45.199219 51.902344 41.738281 53.585938 C 36.953125 55.949219 31.40625 56.769531 26.148438 55.867188 C 21.871094 55.171875 17.777344 53.316406 14.386719 50.621094 C 9.910156 47.085938 6.6875 42.027344 5.359375 36.476562 C 4.316406 31.957031 4.378906 27.214844 5.796875 22.785156 C 8.582031 13.71875 16.601562 6.628906 25.972656 5.074219 Z M 25.972656 5.074219 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={color}
        d="M 27.988281 6.414062 C 28.867188 6.394531 29.777344 6.316406 30.648438 6.433594 C 31.386719 6.550781 31.65625 7.410156 31.414062 8.042969 C 31.1875 8.320312 30.902344 8.554688 30.636719 8.796875 C 31.097656 9.375 31.53125 9.96875 31.957031 10.566406 C 31.597656 10.574219 31.242188 10.578125 30.882812 10.582031 C 30.492188 10.027344 30.09375 9.472656 29.6875 8.925781 C 29.4375 8.90625 29.183594 8.886719 28.933594 8.871094 C 28.941406 9.46875 28.953125 10.0625 28.972656 10.660156 C 28.679688 10.667969 28.386719 10.671875 28.097656 10.675781 C 28.070312 9.253906 28.03125 7.832031 27.988281 6.414062 Z M 27.988281 6.414062 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={color}
        d="M 25.105469 6.945312 C 26.050781 8.195312 26.976562 9.496094 27.824219 10.816406 C 26.871094 10.851562 26.777344 10.707031 26.179688 10.070312 C 25.664062 10.1875 25.152344 10.308594 24.632812 10.429688 C 24.589844 10.773438 24.546875 11.121094 24.503906 11.464844 C 24.210938 11.535156 23.917969 11.601562 23.621094 11.671875 C 23.835938 10.164062 24.007812 8.644531 24.257812 7.140625 C 24.535156 7.066406 24.820312 7 25.105469 6.945312 Z M 25.105469 6.945312 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={background}
        d="M 28.898438 7.046875 C 29.433594 7.089844 29.984375 7.109375 30.511719 7.203125 C 30.585938 7.464844 30.59375 7.734375 30.535156 8.011719 C 30.007812 8.144531 29.441406 8.191406 28.902344 8.257812 C 28.898438 7.855469 28.898438 7.449219 28.898438 7.046875 Z M 28.898438 7.046875 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={color}
        d="M 35.113281 6.789062 C 36.046875 7.039062 36.980469 7.285156 37.914062 7.519531 C 37.875 7.753906 37.835938 7.988281 37.792969 8.222656 C 37.113281 8.0625 36.433594 7.890625 35.757812 7.722656 C 35.679688 8.042969 35.597656 8.367188 35.515625 8.6875 C 36.089844 8.835938 36.664062 8.984375 37.238281 9.136719 L 37.042969 9.835938 C 36.46875 9.683594 35.894531 9.535156 35.320312 9.382812 C 35.171875 9.96875 35.019531 10.550781 34.871094 11.136719 C 34.589844 11.058594 34.3125 10.988281 34.035156 10.910156 C 34.390625 9.539062 34.75 8.160156 35.113281 6.789062 Z M 35.113281 6.789062 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={background}
        d="M 24.910156 8.027344 C 25.222656 8.488281 25.535156 8.945312 25.847656 9.402344 C 25.46875 9.492188 25.09375 9.582031 24.714844 9.667969 C 24.78125 9.121094 24.84375 8.574219 24.910156 8.027344 Z M 24.910156 8.027344 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={color}
        d="M 39.289062 8.003906 C 40.09375 8.417969 41.003906 8.75 41.707031 9.320312 C 42.21875 9.757812 42.050781 10.523438 41.652344 10.980469 C 41.300781 11.164062 40.898438 11.261719 40.519531 11.378906 C 40.679688 12.089844 40.800781 12.808594 40.914062 13.527344 C 40.59375 13.375 40.269531 13.222656 39.949219 13.066406 C 39.847656 12.394531 39.742188 11.722656 39.632812 11.046875 C 39.425781 10.890625 39.21875 10.738281 39.011719 10.585938 C 38.738281 11.128906 38.464844 11.675781 38.195312 12.226562 C 37.9375 12.09375 37.679688 11.96875 37.417969 11.839844 C 38.046875 10.5625 38.664062 9.28125 39.289062 8.003906 Z M 39.289062 8.003906 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={color}
        d="M 22.113281 8.789062 C 21.933594 8.988281 21.75 9.1875 21.570312 9.386719 C 21.109375 9.375 20.480469 9.144531 20.074219 9.402344 C 18.695312 10.089844 20.058594 12.707031 21.417969 11.996094 C 22.007812 11.753906 22.046875 11.273438 22.25 10.742188 C 21.839844 10.863281 21.433594 10.988281 21.027344 11.125 L 20.78125 10.496094 C 21.332031 10.234375 21.886719 9.972656 22.433594 9.707031 C 22.703125 10.253906 22.964844 10.800781 23.230469 11.351562 C 22.574219 12.082031 22.007812 12.757812 20.964844 12.878906 C 19.058594 13.054688 17.789062 10.238281 19.339844 9.007812 C 20.261719 8.238281 21.066406 8.378906 22.113281 8.789062 Z M 22.113281 8.789062 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={background}
        d="M 39.796875 9.023438 C 40.324219 9.300781 40.835938 9.585938 41.261719 10.011719 C 41.09375 10.226562 40.90625 10.417969 40.707031 10.589844 C 40.203125 10.453125 39.707031 10.238281 39.222656 10.042969 C 39.410156 9.703125 39.601562 9.363281 39.796875 9.023438 Z M 39.796875 9.023438 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={color}
        d="M 16.738281 10.578125 C 17.46875 11.464844 18.347656 12.367188 18.808594 13.421875 C 19.121094 14.4375 17.464844 15.765625 16.53125 15.371094 C 15.511719 14.753906 14.824219 13.515625 14.058594 12.625 C 14.28125 12.441406 14.507812 12.261719 14.730469 12.082031 C 15.398438 12.894531 15.992188 13.835938 16.785156 14.53125 C 17.394531 14.730469 17.644531 14.316406 18.039062 13.945312 C 17.683594 12.832031 16.675781 11.929688 16 10.988281 C 16.246094 10.851562 16.492188 10.714844 16.738281 10.578125 Z M 16.738281 10.578125 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={color}
        d="M 43.714844 10.359375 C 44.550781 11 45.390625 11.636719 46.226562 12.277344 C 46.082031 12.46875 45.9375 12.664062 45.792969 12.855469 C 45.179688 12.390625 44.574219 11.925781 43.957031 11.460938 C 43.773438 11.710938 43.585938 11.957031 43.398438 12.207031 C 43.964844 12.640625 44.53125 13.078125 45.101562 13.519531 L 44.648438 14.070312 C 44.082031 13.636719 43.519531 13.210938 42.953125 12.78125 C 42.722656 13.085938 42.492188 13.390625 42.257812 13.695312 C 42.890625 14.179688 43.519531 14.660156 44.148438 15.144531 L 43.710938 15.710938 C 42.851562 15.058594 41.992188 14.40625 41.132812 13.746094 C 41.824219 12.84375 42.515625 11.933594 43.207031 11.027344 C 43.375 10.804688 43.546875 10.582031 43.714844 10.359375 Z M 43.714844 10.359375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={color}
        d="M 26.875 12.351562 C 32.390625 11.261719 38.230469 12.769531 42.46875 16.460938 C 46.265625 19.695312 48.628906 24.484375 48.875 29.464844 C 49.070312 32.957031 48.242188 36.488281 46.515625 39.527344 C 44.398438 43.300781 40.890625 46.285156 36.824219 47.761719 C 33.578125 48.980469 29.960938 49.210938 26.578125 48.492188 C 22.378906 47.589844 18.53125 45.191406 15.902344 41.796875 C 13.746094 39.042969 12.40625 35.679688 12.078125 32.199219 C 11.546875 26.871094 13.488281 21.367188 17.257812 17.566406 C 19.839844 14.902344 23.238281 13.0625 26.875 12.351562 Z M 26.875 12.351562 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={color}
        d="M 47.34375 13.351562 C 48.074219 14.101562 48.75 14.910156 49.441406 15.695312 L 48.890625 16.160156 C 48.382812 15.585938 47.878906 15.015625 47.371094 14.441406 C 47.140625 14.652344 46.90625 14.859375 46.675781 15.070312 C 47.140625 15.601562 47.613281 16.132812 48.082031 16.664062 L 47.554688 17.148438 C 47.078125 16.617188 46.605469 16.082031 46.132812 15.546875 C 45.84375 15.800781 45.554688 16.058594 45.269531 16.3125 C 45.796875 16.910156 46.320312 17.503906 46.84375 18.105469 C 46.664062 18.257812 46.484375 18.410156 46.300781 18.5625 C 45.582031 17.757812 44.871094 16.953125 44.15625 16.148438 C 45.21875 15.21875 46.246094 14.238281 47.34375 13.351562 Z M 47.34375 13.351562 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={color}
        d="M 13.90625 14.328125 C 13.757812 14.550781 13.609375 14.773438 13.460938 14.996094 C 12.527344 15.234375 12.3125 15.238281 12.003906 16.203125 C 13.125 16.449219 14.085938 14.664062 15.210938 15.480469 C 16.433594 16.355469 15.371094 18.132812 14.214844 18.414062 C 13.792969 18.425781 13.359375 18.347656 12.941406 18.300781 C 13.078125 18.0625 13.214844 17.824219 13.351562 17.585938 C 13.71875 17.574219 14.082031 17.539062 14.441406 17.480469 C 14.9375 17.0625 15.078125 16.710938 14.757812 16.101562 C 13.871094 16.273438 12.734375 17.566406 11.890625 16.996094 C 10.996094 16.382812 11.355469 15.371094 12.003906 14.730469 C 12.5625 14.105469 13.164062 14.316406 13.90625 14.328125 Z M 13.90625 14.328125 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={background}
        d="M 28.621094 17.914062 C 29.71875 18.730469 30.742188 19.644531 31.804688 20.503906 C 30.804688 21.960938 29.519531 23.304688 28.691406 24.867188 C 28.980469 25.804688 29.4375 26.699219 29.828125 27.601562 C 28.808594 27.980469 27.785156 28.359375 26.761719 28.742188 C 23.90625 26.386719 21.054688 24.035156 18.199219 21.679688 C 21.675781 20.4375 25.132812 19.117188 28.621094 17.914062 Z M 28.621094 17.914062 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={background}
        d="M 34.726562 18.773438 L 34.863281 18.785156 C 37.167969 19 39.46875 19.273438 41.773438 19.515625 C 40.363281 21.402344 38.945312 23.285156 37.535156 25.171875 C 35.195312 24.90625 32.859375 24.640625 30.519531 24.375 C 31.917969 22.503906 33.351562 20.660156 34.726562 18.773438 Z M 34.726562 18.773438 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={background}
        d="M 42.78125 20.398438 C 43.761719 22.449219 44.59375 24.578125 45.515625 26.652344 C 44.113281 28.511719 42.710938 30.375 41.300781 32.230469 C 40.394531 30.128906 39.480469 28.03125 38.578125 25.929688 C 39.992188 24.097656 41.347656 22.214844 42.78125 20.398438 Z M 42.78125 20.398438 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={background}
        d="M 17.21875 22.972656 C 20.054688 25.300781 22.898438 27.625 25.730469 29.957031 C 25.125 33.515625 24.507812 37.070312 23.894531 40.625 C 21.058594 38.285156 18.222656 35.941406 15.386719 33.605469 C 15.992188 30.058594 16.609375 26.515625 17.21875 22.972656 Z M 17.21875 22.972656 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={color}
        d="M 8.035156 25.941406 C 8.789062 25.546875 9.675781 26.207031 9.582031 27.039062 C 9.445312 27.835938 8.484375 28.222656 7.84375 27.710938 C 7.269531 27.199219 7.367188 26.316406 8.035156 25.941406 Z M 8.035156 25.941406 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={background}
        d="M 30.414062 25.703125 C 32.738281 25.957031 35.0625 26.210938 37.386719 26.464844 C 38.289062 28.566406 39.203125 30.664062 40.105469 32.765625 C 37.785156 32.511719 35.460938 32.261719 33.140625 32.007812 C 32.226562 29.910156 31.324219 27.804688 30.414062 25.703125 Z M 30.414062 25.703125 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={color}
        d="M 52.027344 25.894531 C 52.75 25.589844 53.53125 26.257812 53.421875 27.019531 C 53.332031 27.800781 52.472656 28.183594 51.8125 27.769531 C 51.0625 27.316406 51.214844 26.175781 52.027344 25.894531 Z M 52.027344 25.894531 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={background}
        d="M 30.460938 29.113281 C 31.117188 30.394531 31.519531 31.835938 32.269531 33.058594 C 33.511719 33.5625 35.113281 33.453125 36.449219 33.675781 C 36.25 34.828125 36.050781 35.980469 35.851562 37.132812 C 32.394531 38.386719 28.941406 39.640625 25.484375 40.886719 C 26.097656 37.332031 26.707031 33.777344 27.324219 30.21875 C 28.367188 29.847656 29.402344 29.445312 30.460938 29.113281 Z M 30.460938 29.113281 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={color}
        d="M 7.6875 29.535156 C 8.339844 29.144531 9.242188 29.621094 9.28125 30.378906 C 9.402344 31.140625 8.4375 31.769531 7.75 31.410156 C 7.015625 31.015625 6.980469 29.976562 7.6875 29.535156 Z M 7.6875 29.535156 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={color}
        d="M 52.070312 29.59375 C 52.847656 29.03125 53.859375 29.703125 53.707031 30.625 C 53.582031 31.355469 52.832031 31.71875 52.179688 31.378906 C 51.484375 30.996094 51.410156 30.042969 52.070312 29.59375 Z M 52.070312 29.59375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={color}
        d="M 7.957031 33.117188 C 8.621094 32.664062 9.609375 33.230469 9.582031 34.019531 C 9.574219 34.792969 8.625 35.40625 7.957031 34.917969 C 7.304688 34.515625 7.292969 33.511719 7.957031 33.117188 Z M 7.957031 33.117188 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={color}
        d="M 51.730469 33.191406 C 52.449219 32.636719 53.4375 33.121094 53.4375 34.015625 C 53.464844 34.742188 52.613281 35.347656 51.960938 34.972656 C 51.25 34.648438 51.09375 33.671875 51.730469 33.191406 Z M 51.730469 33.191406 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={background}
        d="M 38.601562 36.261719 C 39.015625 36.980469 39.011719 37.035156 38.703125 37.796875 C 38.417969 37.597656 38.136719 37.398438 37.855469 37.195312 C 38.085938 36.871094 38.339844 36.5625 38.601562 36.261719 Z M 38.601562 36.261719 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={background}
        d="M 36.796875 38.0625 C 36.925781 38.4375 37.398438 38.804688 37.058594 39.183594 C 36.988281 39.535156 36.390625 39.226562 36.171875 39.191406 C 36.066406 38.542969 36.378906 38.460938 36.796875 38.0625 Z M 36.796875 38.0625 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={background}
        d="M 39.574219 38.363281 C 39.832031 37.839844 40.25 38.699219 40.480469 38.871094 C 40.316406 39.050781 40.144531 39.21875 39.957031 39.371094 C 39.324219 39.335938 39.003906 38.796875 39.574219 38.363281 Z M 39.574219 38.363281 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={background}
        d="M 33.792969 39.722656 C 34.320312 39.761719 34.269531 39.972656 34.40625 40.429688 C 34.226562 40.519531 33.835938 40.988281 33.632812 40.75 C 33.113281 40.535156 33.207031 39.710938 33.792969 39.722656 Z M 33.792969 39.722656 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={background}
        d="M 36.003906 40.566406 C 36.554688 39.914062 37.511719 40.921875 36.761719 41.316406 C 36.261719 41.621094 35.769531 41.164062 36.003906 40.566406 Z M 36.003906 40.566406 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={background}
        d="M 38.511719 40.945312 C 38.808594 40.296875 39.601562 41.089844 39.292969 41.484375 C 39.125 42.3125 37.648438 41.351562 38.511719 40.945312 Z M 38.511719 40.945312 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={background}
        d="M 34.859375 42.121094 C 35.339844 41.300781 36.199219 42.769531 35.398438 42.929688 C 34.972656 43.152344 34.214844 42.5 34.859375 42.121094 Z M 34.859375 42.121094 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={color}
        d="M 15.496094 43.425781 C 15.925781 44.054688 15.632812 44.59375 15.535156 45.277344 C 15.316406 45.082031 15.101562 44.890625 14.890625 44.695312 C 14.828125 43.855469 14.894531 43.152344 13.773438 43.257812 C 13.953125 44.039062 14.359375 44.773438 14.480469 45.5625 C 14.449219 46.367188 13.574219 46.929688 12.828125 46.667969 C 12.121094 46.390625 11.574219 45.691406 11.40625 44.960938 C 11.476562 44.503906 11.632812 44.066406 11.773438 43.621094 C 11.960938 43.785156 12.152344 43.953125 12.339844 44.121094 C 12.363281 44.546875 12.175781 45.160156 12.464844 45.5 C 12.789062 45.933594 13.144531 45.910156 13.617188 45.777344 C 13.542969 44.816406 12.460938 43.527344 13.269531 42.734375 C 14.144531 42.039062 14.984375 42.65625 15.496094 43.425781 Z M 15.496094 43.425781 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={color}
        d="M 46.316406 42.3125 L 46.839844 42.804688 C 46.339844 43.378906 45.839844 43.953125 45.335938 44.527344 L 46.03125 45.148438 C 46.503906 44.613281 46.980469 44.074219 47.453125 43.535156 C 47.632812 43.699219 47.808594 43.863281 47.988281 44.023438 C 47.519531 44.554688 47.046875 45.089844 46.578125 45.625 C 46.867188 45.875 47.152344 46.128906 47.445312 46.386719 C 47.972656 45.789062 48.492188 45.195312 49.019531 44.597656 C 49.199219 44.761719 49.375 44.921875 49.554688 45.082031 C 48.84375 45.890625 48.128906 46.699219 47.414062 47.507812 C 46.347656 46.574219 45.28125 45.628906 44.21875 44.695312 C 44.917969 43.902344 45.617188 43.105469 46.316406 42.3125 Z M 46.316406 42.3125 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={color}
        d="M 16.714844 45.035156 C 16.976562 45.117188 17.238281 45.199219 17.5 45.292969 C 17.011719 45.964844 16.480469 46.605469 15.984375 47.277344 C 15.726562 47.679688 15.398438 47.957031 15.582031 48.476562 C 15.808594 48.730469 16.085938 48.941406 16.410156 49.105469 C 17.363281 48.46875 18.015625 47.34375 18.738281 46.472656 C 18.964844 46.648438 19.191406 46.824219 19.417969 47.003906 C 18.644531 47.898438 17.96875 49.152344 16.953125 49.773438 C 15.835938 50.1875 14.007812 48.527344 14.871094 47.378906 C 15.398438 46.554688 16.023438 45.730469 16.714844 45.035156 Z M 16.714844 45.035156 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={color}
        d="M 43.65625 45.25 C 43.84375 45.386719 44.03125 45.527344 44.21875 45.667969 C 43.597656 46.171875 42.960938 46.660156 42.328125 47.152344 C 42.515625 47.398438 42.707031 47.644531 42.898438 47.890625 C 43.464844 47.457031 44.027344 47.027344 44.59375 46.59375 L 45.035156 47.164062 C 44.464844 47.597656 43.898438 48.035156 43.332031 48.46875 C 43.566406 48.769531 43.804688 49.074219 44.039062 49.378906 C 44.667969 48.898438 45.296875 48.417969 45.925781 47.941406 C 46.074219 48.128906 46.222656 48.316406 46.367188 48.503906 C 45.507812 49.164062 44.648438 49.820312 43.785156 50.476562 C 42.925781 49.347656 42.0625 48.222656 41.199219 47.097656 C 42.015625 46.476562 42.808594 45.828125 43.65625 45.25 Z M 43.65625 45.25 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={color}
        d="M 21.492188 48.074219 C 22.582031 48.390625 22.878906 49.054688 23.101562 50.089844 C 22.839844 50.046875 22.578125 50.003906 22.3125 49.960938 C 22.035156 49.550781 21.824219 48.871094 21.269531 48.777344 C 20.214844 48.476562 19.460938 49.847656 19.613281 50.734375 C 19.722656 51.503906 20.582031 51.550781 21.167969 51.761719 C 21.304688 51.578125 21.445312 51.390625 21.582031 51.203125 C 21.277344 51.035156 20.972656 50.867188 20.667969 50.699219 C 20.769531 50.488281 20.871094 50.277344 20.972656 50.066406 C 21.546875 50.339844 22.113281 50.621094 22.679688 50.898438 C 22.300781 51.371094 22.050781 52.71875 21.257812 52.480469 C 20.363281 52.480469 19.355469 52.070312 18.929688 51.238281 C 18.171875 49.679688 19.699219 47.433594 21.492188 48.074219 Z M 21.492188 48.074219 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={color}
        d="M 40.402344 47.894531 C 41.484375 48.355469 41.382812 49.1875 40.871094 50.066406 C 41.53125 50.390625 42.171875 50.753906 42.808594 51.117188 C 42.507812 51.277344 42.203125 51.4375 41.898438 51.59375 C 41.070312 51.097656 40.296875 50.664062 39.304688 50.59375 C 39.558594 51.21875 39.832031 51.835938 40.105469 52.449219 L 39.355469 52.824219 C 38.730469 51.546875 38.105469 50.265625 37.492188 48.984375 C 38.375 48.621094 39.4375 47.847656 40.402344 47.894531 Z M 40.402344 47.894531 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={background}
        d="M 40.132812 48.644531 L 40.492188 49.230469 C 40.199219 49.714844 39.546875 49.988281 39.089844 50.296875 C 38.917969 49.9375 38.742188 49.570312 38.570312 49.210938 C 39.085938 49.007812 39.597656 48.796875 40.132812 48.644531 Z M 40.132812 48.644531 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={color}
        d="M 36.941406 49.1875 C 36.992188 49.421875 37.046875 49.65625 37.097656 49.890625 C 36.433594 50.0625 35.777344 50.238281 35.113281 50.410156 C 35.203125 50.730469 35.289062 51.054688 35.378906 51.375 C 35.953125 51.222656 36.527344 51.074219 37.105469 50.925781 L 37.277344 51.632812 C 36.707031 51.78125 36.136719 51.929688 35.566406 52.078125 C 35.722656 52.664062 35.875 53.25 36.03125 53.835938 C 35.75 53.90625 35.46875 53.972656 35.1875 54.042969 C 34.824219 52.671875 34.464844 51.304688 34.097656 49.933594 C 35.046875 49.679688 35.992188 49.429688 36.941406 49.1875 Z M 36.941406 49.1875 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={color}
        d="M 26.171875 49.640625 C 26.402344 51.199219 26.625 52.761719 26.859375 54.324219 C 26.558594 54.253906 26.253906 54.183594 25.949219 54.117188 C 25.902344 53.769531 25.859375 53.425781 25.8125 53.082031 C 25.261719 52.953125 24.714844 52.820312 24.167969 52.6875 C 23.972656 52.972656 23.777344 53.257812 23.582031 53.546875 C 23.289062 53.472656 22.996094 53.398438 22.703125 53.328125 C 23.457031 52.261719 24.164062 51.164062 24.933594 50.113281 C 25.269531 49.546875 25.570312 49.695312 26.171875 49.640625 Z M 26.171875 49.640625 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={color}
        d="M 28.214844 50.226562 C 29.058594 50.167969 29.945312 50.171875 30.777344 50.324219 C 31.488281 50.460938 31.769531 51.25 31.558594 51.886719 C 31.347656 52.21875 30.996094 52.464844 30.710938 52.730469 C 31.164062 53.339844 31.558594 53.980469 31.929688 54.636719 L 30.929688 54.417969 C 30.3125 53.726562 29.894531 52.957031 29.0625 52.476562 C 28.992188 53.136719 28.945312 53.792969 28.902344 54.453125 L 28.0625 54.453125 C 28.113281 53.046875 28.070312 51.625 28.214844 50.226562 Z M 28.214844 50.226562 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={background}
        d="M 25.507812 50.644531 C 25.574219 51.203125 25.640625 51.757812 25.703125 52.316406 C 25.332031 52.226562 24.960938 52.136719 24.589844 52.042969 C 24.894531 51.578125 25.199219 51.113281 25.507812 50.644531 Z M 25.507812 50.644531 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={background}
        d="M 29.019531 50.867188 C 29.574219 50.933594 30.117188 50.976562 30.664062 51.097656 C 30.679688 51.359375 30.675781 51.621094 30.660156 51.886719 C 30.132812 52.007812 29.5625 52.03125 29.023438 52.082031 C 29.019531 51.675781 29.019531 51.273438 29.019531 50.867188 Z M 29.019531 50.867188 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
    </svg>
  );
}

export default SinAzucarIcon;
