import React, { useState } from 'react';
import { ProductWrapper, ProductImage, ProductInfo, ProductTitle, ProductDescription, ProductPrice, TitleWrapper, IconImage } from './styles/ProductStyles';
import ProductModal from './ProductModal';
import { truncateText } from '../utils/truncateText'; // Importamos la función de truncado
import TagIcons from "./TagIcons";
import Price from "./Price";

function Product({ images, title, description, price, productModifiers, modifiers, variantStyle, style, tags }) { // Añadimos el prop `tags`
    const [showModal, setShowModal] = useState(false);
    const image = images && images.length > 0 ? images[0] : "";
    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    const infoColClass = image ? "col-8 ml-0" : "col-12";

    const truncatedTitle = truncateText(title, 35); // Truncamos el título
    const truncatedDescription = truncateText(description, 70); // Truncamos la descripción

    const product = { image, title, description, price, productModifiers };

    if (!style) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <ProductWrapper className="row mb-3" onClick={handleOpenModal} style={style.products}>
                {image && variantStyle !== 1 && (
                    <div className="col-4 mr-0" >
                        <ProductImage style={style.products} src={image} alt={title} className="img-fluid" />
                    </div>
                )}
                <div className={infoColClass} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <ProductInfo style={style.products}>
                        <TitleWrapper>
                            <ProductTitle style={style.products}>{truncatedTitle}</ProductTitle> 
                            <div style={{ marginLeft: 'auto' }}>
                                <TagIcons tags={tags} style={style.products.ProductIcon} />
                            </div>
                        </TitleWrapper>
                        <ProductDescription style={style.products}>{truncatedDescription}</ProductDescription>
                        <ProductPrice style={style.products}><Price amount={price}></Price></ProductPrice>
                    </ProductInfo>
                </div>
                {image && variantStyle === 1 && (
                    <div className="col-4">
                        <ProductImage style={style.products} src={image} alt={title} className="img-fluid" />
                    </div>
                )}
            </ProductWrapper>
            <ProductModal 
                show={showModal} 
                onClose={handleCloseModal} 
                product={product} 
                tags={tags}
                style={style?.modalProducts} 
                modifiers={modifiers}
            />
        </>
    );
}

export default Product;
