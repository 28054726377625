import styled from 'styled-components';

export const HeaderImageProduct = styled.img`
    background-size: cover;
    background-position: center;
    width: 100%;
    border-radius: 5px;
    
`;

export const TitleHeader = styled.h2`
    font-family: ${(props) => props.style ? props.style.section.font_family : 'sans-serif'};
    font-size: ${(props) => props.style ? props.style.section.font_size : '16px'};
    color: ${(props) => props.style ? props.style.section.font_color : ''};
    font-weight: bold;
    
`;

export const PlaceholderImage = styled.div`
    height: 300px;
    max-width: 100%;
    background-color:${(props) => props.styleConfig ? props.styleConfig.section.image.background_color : 'black'}; 
    display: flex;
    align-items: center;
    justify-content: center;
    color:${(props) => props.styleConfig ? props.styleConfig.section.image.font_color : 'white'};
    font-size: ${(props) => props.styleConfig ? props.styleConfig.section.image.font_size : '24px'};
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
`;



export const ImageWithTitle = styled.div`
    position: relative;
    width: 100%;

    height: 300px;

    .image-container {
        position: relative; /* Cambia a relativo para posicionar la capa de fondo correctamente */
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute; /* Asegura que la imagen cubra todo el contenedor */
            top: 0;
            left: 0;
            z-index: 1; /* Asegura que la imagen esté por debajo de la capa de fondo */
        }

        .background-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5); /* Fondo negro con 50% de transparencia */
            z-index: 2; /* Asegura que la capa de fondo esté por encima de la imagen */
        }
    }

    .title-overlay {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: ${(props) => props.styleConfig ? props.styleConfig.section.image.font_color : 'white'};
        font-size: ${(props) => props.styleConfig ? props.styleConfig.section.image.font_size : '24px'};
        font-weight: bold;
        text-align: center;
        font-family: "Urbanist", sans-serif;
        z-index: 3; /* Asegura que el texto esté por encima de la capa de fondo */
    }
`;
