let styleConfigPromise = null;


const fetchStyleConfig = async () => {
    if (!styleConfigPromise) {
        styleConfigPromise = fetch(`${process.env.PUBLIC_URL}/menuData.json`)
        .then(response => response.json())
        .then(data => data);
    }
    return styleConfigPromise;
};

export const getStyleConfig = async () => {
    const config = await fetchStyleConfig();
    return config.metadata.style;
};

export default fetchStyleConfig;
