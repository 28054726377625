import React, { useState, useEffect } from 'react';
import { getStyleConfig } from './styleConfig';

const StyleConfigProvider = ({ children }) => {
    const [styleConfig, setStyleConfig] = useState(null);

    useEffect(() => {
        const fetchConfig = async () => {
            const config = await getStyleConfig();
            setStyleConfig(config);
        };

        fetchConfig();
    }, []);

    return React.cloneElement(children, { styleConfig });
};

export default StyleConfigProvider;
