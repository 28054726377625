import styled from 'styled-components';
import { BiSearch } from 'react-icons/bi';

export const ButtonIcon = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: ${props => props.size || '24px'};
  color: ${props => props.color || 'inherit'};
  &:hover {
    opacity: 0.8;
  }
  &:focus {
    outline: none;
  }
`;


export const StickyMenuWrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  border-bottom: 1px solid #ddd;
  background-color: ${(props) => {
    return props.styleConfig ? props.styleConfig.menu.background_color : 'black';
  }};
  color: ${(props) => props.styleConfig ? props.styleConfig.menu.font_color : ''};
  margin-left: -2px;

 
  @media only screen and (min-width: 1024px) {
    width: 430px; /* Ancho del iPhone 14 Pro en puntos */
    left: 51%;
    transform: translateX(-50%);
  }
`;

export const StickyMenuContent = styled.div`
  max-width: 100%;
  margin: 0 auto;
`;

export const SearchInputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 5px 5px 5px 30px;
  margin-left: 10px;
  border-radius: 20px;
  border: 1px solid #ccc;
  &:focus {
    box-shadow: none;
    border-color: #ccc;
  }
`;

export const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 0;
  color: ${(props) => props.styleConfig ? props.styleConfig.menu.sticky.icons_color : 'white'};
`;

export const SearchIcon = styled(BiSearch)`
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
`;

export const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  overflow-x: auto;
  white-space: nowrap;
  padding: 15px 0;
  background-color: ${(props) => props.styleConfig ? props.styleConfig.menu.background_color : ''};
  font-family: ${(props) => props.styleConfig ? props.styleConfig.menu.font : 'sans-serif'};
  font-size: ${(props) => props.styleConfig ? props.styleConfig.menu.sticky.font_size : 16}px;
  color: ${(props) => props.styleConfig ? props.styleConfig.menu.sticky.font_color : ''};
  padding-left: ${(props) => props.hasLeftArrow ? '75px' : '0px'};
  padding-right: ${(props) => props.hasRightArrow ? '75px' : '0px'};
`;

export const MenuItem = styled.div.attrs((props) => ({
  isSelected: props.isSelected,
}))`
  padding: 10px;
  cursor: pointer;
  border-bottom: ${(props) => props.isSelected? props.styleConfig.menu.isSelected : 'none'};
  color: ${(props) => props.isSelected ? props.styleConfig.menu.colorIsSelected :''};
`;
