import React from 'react';
import Section from './Section';

const Sections = ({ sections, filteredProducts,modifiers,styleConfig }) => (
    sections.map((section) => (
        <Section
            key={section.name}
            section={section}
            filteredProducts={filteredProducts}
            styleConfig={styleConfig}
            modifiers={modifiers}
        />
    ))
);

export default Sections;
