import React, { useState } from 'react';
import {
  StyledTableContainer,
  StyledTitle,
  StyledTitleContainer,
  StyledTable,
  StyledTableRow,
  StyledTableCell,
  StyledRequiredLabel
} from './styles/ModifierTableStyles';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ModalPrice } from './styles/ProductModalStyles';
import Price from "./Price";

const ModifierTable = ({productModifiers,  modifiers, priceProduct, style }) => {
  const [selectedItems, setSelectedItems] = useState({});

  if (!productModifiers || productModifiers.length === 0) return null;

  const handleCheckboxChange = (section, item, isChecked) => {
    setSelectedItems((prevSelectedItems) => {
      const updatedItems = { ...prevSelectedItems };
      if (isChecked) {
        if (!updatedItems[section]) {
          updatedItems[section] = {};
        }
        updatedItems[section][item.toteat_id] = item.price;
      } else {
        if (updatedItems[section]) {
          delete updatedItems[section][item.toteat_id];
          if (Object.keys(updatedItems[section]).length === 0) {
            delete updatedItems[section];
          }
        }
      }
      return updatedItems;
    });
  };

  const extractNumericValue = (price) => {
    const numericValue = price.replace(/[^\d.-]/g, '');
    return parseFloat(numericValue) || 0;
  };

  const calculateTotalPrice = () => {
    let totalPrice = extractNumericValue(priceProduct);
    for (const section in selectedItems) {
      for (const itemId in selectedItems[section]) {
        const priceModifier = extractNumericValue(selectedItems[section][itemId]);
        totalPrice += priceModifier;
      }
    }
    return totalPrice;
  };
  const getSectionName = (section_id, modifiers) => {
    const modifier = modifiers.find(mod => mod.section_id === section_id);
    return modifier ? modifier.name : 'Unknown Section';
  };
  
  return (
    <StyledTableContainer style={style.modifierTable}>
      {productModifiers.map((modifier, index) => (
        <React.Fragment key={index}>
          {modifier.items && modifier.items.length > 0 && (
            <>
              <StyledTitleContainer>
                <StyledTitle style={style.modifierTable}>{getSectionName(modifier.section_id, modifiers)}</StyledTitle>
                {modifier.minQuantity >= 1 && (
                  <StyledRequiredLabel style={style.modifierTable.labelMandatory}>Obligatorio</StyledRequiredLabel>
                )}
              </StyledTitleContainer>
              <StyledTable style={style.modifierTable}>
                <thead>
                  <StyledTableRow style={style.modifierTable}>
                    <th style={{ width: '40px', borderBottom: 'none' }}></th>
                    <th style={{ borderBottom: 'none' }}></th>
                    <th style={{ borderBottom: 'none' }}></th>
                  </StyledTableRow>
                </thead>
                <tbody>
                  {modifier.items.map((item) => (
                    <StyledTableRow key={item.toteat_id} style={style.modifierTable}>
                      <StyledTableCell style={style.modifierTable}>
                        <input
                          type="checkbox"
                          className="form-check-input"
                          onChange={(e) =>
                            handleCheckboxChange(modifier.section, item, e.target.checked)
                          }
                        />
                      </StyledTableCell>
                      <StyledTableCell style={style.modifierTable}>{item.name}</StyledTableCell>
                      <StyledTableCell align="right" style={style.modifierTable}> <Price amount={item.price} /> </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </tbody>
              </StyledTable>
            </>
          )}
        </React.Fragment>
      ))}
      <ModalPrice style={style.price}>Total Price: ${calculateTotalPrice().toFixed(2)}</ModalPrice>
    </StyledTableContainer>
  );
};

export default ModifierTable;
