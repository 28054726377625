import React from "react";
import {
  ModalOverlay,
  ModalContent,
  ModalImage,
  ModalInfo,
  ModalTitle,
  ModalDescription,
  ModalPrice,
  HeartButton,
  TitleContainer,
  CloseButton,
  Footer,
  CloseFooterButton,
} from "./styles/ProductModalStyles";
import TagIcons from "./TagIcons";
import ModifierTable from "./ModifierTable";
import { BiHeart } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import Price from "./Price";

const ProductModal = ({ show, onClose, product, tags, modifiers, style }) => {
  if (!show) return null;
  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()} style={style}>
        <CloseButton onClick={onClose} style={style}>
          <IoClose size={24} />
        </CloseButton>
        {product.image && (
          <ModalImage src={product.image} alt={product.title} />
        )}
        <ModalInfo>
          <TitleContainer>
            <ModalTitle style={style.title}>{product.title}</ModalTitle>
            <TagIcons tags={tags} style={style.ProductIcon} />
          </TitleContainer>
          <ModalDescription style={style.description}>
            {product.description}
          </ModalDescription>
          <ModifierTable
            productModifiers={product.productModifiers}
            priceProduct={product.price}
            modifiers={modifiers}
            style={style}
          />
          <div className="d-flex justify-content-between align-items-center mt-3">
            {product.productModifiers && product.productModifiers.length === 0 && (
              <>
                <ModalPrice style={style.price}>
                  <Price amount={product.price} />
                </ModalPrice>
                <HeartButton style={style}>
                  <BiHeart size={30} />
                </HeartButton>
              </>
            )}
          </div>
        </ModalInfo>
        <Footer>
          <CloseFooterButton onClick={onClose}>Close</CloseFooterButton>
        </Footer>
      </ModalContent>
    </ModalOverlay>
  );
};

export default ProductModal;
