import React, { useState, useRef, useEffect } from 'react';
import { HeaderImage, SearchInputWrapper, SearchInput, IconButton, Banner, SearchIcon, Menu, StickyHeader, MenuItem, ArrowLeft, ArrowRight, BackgroundOverlay } from './styles/HeaderStyles';
import { BiHeart, BiShoppingBag } from 'react-icons/bi';
import { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';

function Header({ onSearch, sections, frontPageImg, banner, restaurantLogo, inputSearchText, setInputSearchText, styleConfig, selectedSection, setSelectedSection }) {
  const { t, i18n } = useTranslation();
  const [iconHeight, setIconHeight] = useState(styleConfig.size_icon? styleConfig.size_icon:80);
  const stickyHeaderRef = useRef(null);
  const menuRef = useRef(null);
  const [menuHeight, setMenuHeight] = useState(0);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  useEffect(() => {
    if (stickyHeaderRef.current) {
      setMenuHeight(stickyHeaderRef.current.offsetHeight);
    }
  }, []);

  useEffect(() => {
    const menuElement = menuRef.current;
    const handleScroll = () => {
      const scrollLeft = menuElement.scrollLeft;
      const scrollWidth = menuElement.scrollWidth;
      const clientWidth = menuElement.clientWidth;

      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
    };

    menuElement.addEventListener('scroll', handleScroll);
    handleScroll(); 

    return () => {
      menuElement.removeEventListener('scroll', handleScroll);
    };
  }, [sections]);

  const toggleLanguage = () => {
    const newLanguage = i18n.language === 'es' ? 'en' : 'es';
    i18n.changeLanguage(newLanguage);
  };

  const handleInputChange = (e) => {
    onSearch(e.target.value);
    setInputSearchText(e.target.value);
  };

  const handleSectionClick = (sectionName) => {
    setSelectedSection(sectionName); 
  };

  const scrollMenu = (direction) => {
    const menuElement = menuRef.current;
    const scrollAmount = 200; 
    menuElement.scrollLeft += direction === 'left' ? -scrollAmount : scrollAmount;
  };

  return (
    <div style={{ position: 'relative' }}>
      <HeaderImage src={frontPageImg} styleConfig={styleConfig?.menu?.HeaderImage} />
      <StickyHeader ref={stickyHeaderRef} styleConfig={styleConfig}>
        <div className="container-fluid">
          <div className="row align-items-center mb-2" style={{ paddingLeft: 0, paddingRight: 0 }}>
            <div className="col-12 d-flex align-items-center justify-content-center">
              <img src={restaurantLogo} alt="Logo" className="img-fluid" style={{ height: `${iconHeight}px` }} />
            </div>
          </div>
          <div className="row align-items-center justify-content-start mt-4">
            <div className="col-10" style={{ paddingLeft: 0 }}>
              <SearchInputWrapper>
                <SearchIcon />
                <SearchInput
                  type="text"
                  placeholder={t('search')}
                  className="form-control rounded-pill pl-5"
                  onChange={handleInputChange}
                  value={inputSearchText}
                />
              </SearchInputWrapper>
            </div>
            <div className="col-2" style={{ paddingLeft: 0, paddingRight: 0 }}>
              <IconButton className="btn" styleConfig={styleConfig}>
                <BiHeart size={25} />
                <BiShoppingBag size={25} />
              </IconButton>
            </div>
          </div>
          <div style={{ position: 'relative' }}>
            {showLeftArrow && (
              <ArrowLeft styleConfig={styleConfig} onClick={() => scrollMenu('left')} />
            )}
            {showRightArrow && (
              <ArrowRight styleConfig={styleConfig} onClick={() => scrollMenu('right')} />
            )}
            <Menu ref={menuRef} styleConfig={styleConfig} hasLeftArrow={showLeftArrow} hasRightArrow={showRightArrow}>
              {sections.filter(section => !section.is_modifier).map((section, index) => (
                <Link
                  key={`${section.name}-${index}`}
                  to={section.name}
                  smooth={true}
                  duration={500}
                  offset={-menuHeight}
                  onClick={() => handleSectionClick(section.name)} // Manejador de clic para actualizar la sección seleccionada
                >
                  <MenuItem isSelected={selectedSection === section.name} styleConfig={styleConfig}>
                    {section.name}
                  </MenuItem>
                </Link>
              ))}
            </Menu>
          </div>
        </div>
      </StickyHeader>
    </div>
  );
}

export default Header;
