import React from "react";

function SinGlutenIcon({ width, color, background }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width={width || 80}
      fill={color}
      strokeWidth={0}
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
    
    <rect x="-6" width="72" fill={background} y="-6" height="72" fill-opacity="1"/>

    <path fill={color} d="M 25.953125 1.746094 C 28.636719 1.398438 31.363281 1.398438 34.046875 1.746094 C 38.761719 2.363281 43.3125 4.34375 47.125 7.160156 C 54.230469 12.464844 58.628906 21.109375 58.519531 30 C 58.59375 35.4375 56.964844 40.78125 54.058594 45.363281 C 48.984375 53.386719 39.808594 58.53125 30.300781 58.519531 C 21.390625 58.714844 12.664062 54.382812 7.289062 47.296875 C 3.546875 42.355469 1.390625 36.21875 1.480469 30 C 1.40625 25.550781 2.53125 21.089844 4.519531 17.121094 C 8.632812 8.902344 16.839844 2.984375 25.953125 1.746094 Z M 25.953125 1.746094 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={background} d="M 26.851562 5.007812 C 32.195312 4.324219 37.714844 5.382812 42.421875 8.011719 C 46.789062 10.5 50.410156 14.246094 52.644531 18.757812 C 54.523438 22.429688 55.34375 26.492188 55.339844 30.601562 C 55.246094 36.058594 53.300781 41.441406 49.90625 45.71875 C 47.695312 48.546875 44.871094 50.824219 41.710938 52.511719 C 36.6875 55.109375 30.777344 55.984375 25.226562 54.875 C 21.175781 54.125 17.371094 52.320312 14.171875 49.726562 C 9.710938 46.148438 6.539062 40.996094 5.359375 35.398438 C 4.621094 31.839844 4.566406 28.078125 5.378906 24.523438 C 6.65625 18.773438 10.054688 13.507812 14.761719 9.964844 C 18.253906 7.246094 22.472656 5.582031 26.851562 5.007812 Z M 26.851562 5.007812 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={color} d="M 27.542969 7.003906 C 27.921875 6.949219 28.300781 6.890625 28.675781 6.835938 C 28.847656 8.15625 29.152344 9.484375 29.1875 10.816406 C 29.179688 12.785156 25.773438 13.277344 25.214844 11.410156 C 24.855469 10.125 24.78125 8.75 24.558594 7.433594 C 24.9375 7.378906 25.320312 7.324219 25.699219 7.265625 C 25.90625 8.488281 25.996094 9.75 26.285156 10.957031 C 26.390625 11.890625 28.121094 11.695312 28.03125 10.804688 C 27.992188 9.535156 27.691406 8.269531 27.542969 7.003906 Z M 27.542969 7.003906 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={color} d="M 30.652344 6.820312 C 31.9375 6.964844 33.226562 7.09375 34.507812 7.238281 C 34.472656 7.5625 34.4375 7.890625 34.402344 8.21875 C 33.949219 8.164062 33.496094 8.113281 33.042969 8.0625 C 32.886719 9.527344 32.734375 10.996094 32.566406 12.460938 C 32.191406 12.40625 31.808594 12.351562 31.429688 12.296875 C 31.585938 10.851562 31.734375 9.40625 31.898438 7.960938 C 31.445312 7.90625 30.996094 7.851562 30.542969 7.796875 C 30.578125 7.472656 30.617188 7.148438 30.652344 6.820312 Z M 30.652344 6.820312 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={color} d="M 36.3125 7.683594 C 37.457031 8.042969 38.582031 8.476562 39.71875 8.871094 C 39.605469 9.1875 39.492188 9.496094 39.382812 9.8125 C 38.605469 9.535156 37.832031 9.265625 37.054688 9 C 36.9375 9.339844 36.820312 9.675781 36.703125 10.011719 C 37.265625 10.210938 37.828125 10.414062 38.390625 10.617188 C 38.285156 10.925781 38.179688 11.234375 38.074219 11.542969 C 37.507812 11.347656 36.9375 11.152344 36.371094 10.953125 C 36.226562 11.367188 36.085938 11.777344 35.9375 12.191406 C 36.742188 12.472656 37.542969 12.753906 38.34375 13.042969 L 38.003906 13.984375 C 36.84375 13.570312 35.675781 13.164062 34.515625 12.746094 C 35.117188 11.0625 35.679688 9.355469 36.3125 7.683594 Z M 36.3125 7.683594 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={color} d="M 20.53125 8.886719 C 21.101562 10.234375 21.667969 11.585938 22.238281 12.933594 C 22.9375 12.632812 23.636719 12.335938 24.335938 12.035156 C 24.464844 12.351562 24.589844 12.664062 24.71875 12.976562 C 23.667969 13.414062 22.613281 13.851562 21.5625 14.289062 C 20.863281 12.640625 20.164062 10.988281 19.46875 9.339844 C 19.820312 9.1875 20.175781 9.035156 20.53125 8.886719 Z M 20.53125 8.886719 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={color} d="M 41.714844 9.898438 C 42.042969 10.074219 42.347656 10.277344 42.652344 10.492188 C 42.578125 11.796875 42.445312 13.097656 42.347656 14.398438 C 42.984375 13.472656 43.621094 12.550781 44.261719 11.625 C 44.566406 11.839844 44.867188 12.046875 45.171875 12.261719 C 44.144531 13.738281 43.128906 15.222656 42.097656 16.699219 C 41.800781 16.480469 41.503906 16.265625 41.203125 16.046875 C 41.335938 14.703125 41.449219 13.355469 41.554688 12.007812 C 40.882812 12.980469 40.214844 13.957031 39.542969 14.929688 C 39.242188 14.714844 38.941406 14.5 38.644531 14.285156 C 39.679688 12.835938 40.628906 11.308594 41.714844 9.898438 Z M 41.714844 9.898438 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={color} d="M 17.101562 11.074219 C 17.535156 11.113281 17.96875 11.296875 18.382812 11.433594 C 18.160156 11.726562 17.941406 12.019531 17.714844 12.308594 C 17.050781 12.210938 16.640625 12.074219 16.132812 12.625 C 15.910156 13.46875 16.4375 14.230469 16.980469 14.824219 C 17.363281 15.226562 17.964844 15.597656 18.496094 15.226562 C 19.007812 14.921875 18.847656 14.394531 18.972656 13.875 C 18.648438 14.074219 18.324219 14.277344 18 14.480469 C 17.804688 14.21875 17.609375 13.957031 17.414062 13.695312 C 17.980469 13.269531 18.546875 12.84375 19.113281 12.417969 C 19.683594 13.179688 20.253906 13.941406 20.824219 14.699219 C 20.507812 14.859375 20.1875 15.011719 19.863281 15.167969 C 19.453125 15.652344 19.113281 16.167969 18.480469 16.371094 C 17.683594 16.636719 16.90625 16.339844 16.316406 15.785156 C 15.632812 15.140625 14.988281 14.167969 14.992188 13.199219 C 14.953125 12.09375 16.03125 11.113281 17.101562 11.074219 Z M 17.101562 11.074219 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={color} d="M 27.445312 14.023438 C 32.761719 13.128906 38.3125 14.953125 41.988281 18.910156 C 46.480469 23.613281 47.722656 30.949219 44.949219 36.851562 C 43.070312 40.976562 39.40625 44.21875 35.078125 45.5625 C 31.90625 46.609375 28.394531 46.613281 25.222656 45.574219 C 21.246094 44.339844 17.824219 41.503906 15.859375 37.835938 C 14.269531 34.90625 13.636719 31.507812 13.996094 28.199219 C 14.371094 25.03125 15.679688 21.984375 17.757812 19.558594 C 20.171875 16.636719 23.71875 14.660156 27.445312 14.023438 Z M 27.445312 14.023438 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={background} d="M 30.167969 16.191406 C 31.523438 17.625 32.714844 19.460938 32.332031 21.527344 C 32.089844 22.804688 31.230469 23.546875 30.1875 24.207031 C 29.113281 23.597656 28.246094 22.847656 27.996094 21.585938 C 27.578125 19.53125 28.792969 17.601562 30.167969 16.191406 Z M 30.167969 16.191406 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={color} d="M 12.988281 16.769531 C 13.617188 17.554688 12.65625 18.226562 12.324219 18.921875 C 9.191406 23.800781 8.304688 29.964844 9.816406 35.550781 C 11.050781 40.199219 13.875 44.316406 17.785156 47.121094 C 17.671875 47.347656 17.554688 47.574219 17.445312 47.800781 C 12.097656 44.117188 8.78125 37.949219 8.371094 31.5 C 8.042969 26.535156 9.445312 21.496094 12.355469 17.457031 C 12.5625 17.226562 12.777344 16.996094 12.988281 16.769531 Z M 12.988281 16.769531 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={color} d="M 47.347656 16.757812 C 49.90625 19.648438 51.351562 23.65625 51.832031 27.449219 C 52.785156 35.152344 49.394531 43.109375 43.078125 47.644531 C 42.753906 47.707031 42.59375 47.539062 42.601562 47.144531 C 43.402344 46.328125 44.359375 45.6875 45.144531 44.84375 C 48.949219 41.035156 51.160156 35.679688 51.207031 30.300781 C 51.265625 26.511719 50.269531 22.722656 48.347656 19.453125 C 47.945312 18.757812 47.417969 18.117188 47.078125 17.386719 Z M 47.347656 16.757812 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={background} d="M 23.390625 24.839844 C 24.957031 24.542969 26.382812 24.238281 27.910156 24.898438 C 29.175781 25.40625 29.929688 26.503906 30.15625 27.824219 C 30.394531 26.582031 31.0625 25.570312 32.222656 25.027344 C 33.789062 24.285156 35.320312 24.511719 36.949219 24.847656 C 36.382812 26.734375 35.4375 28.761719 33.445312 29.472656 C 32.234375 29.894531 31.210938 29.53125 30.164062 28.921875 C 29.066406 29.574219 27.949219 29.914062 26.703125 29.410156 C 24.816406 28.625 23.929688 26.683594 23.390625 24.839844 Z M 23.390625 24.839844 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={background} d="M 28.617188 31.089844 C 29.507812 31.679688 29.914062 32.574219 30.160156 33.574219 C 30.398438 32.488281 30.886719 31.558594 31.875 30.984375 C 33.503906 30.054688 35.191406 30.234375 36.949219 30.640625 C 36.398438 32.539062 35.445312 34.546875 33.464844 35.277344 C 32.242188 35.722656 31.234375 35.28125 30.164062 34.703125 C 29.089844 35.28125 28.066406 35.730469 26.835938 35.273438 C 24.886719 34.558594 23.910156 32.507812 23.394531 30.632812 C 25.195312 30.214844 26.980469 30.046875 28.617188 31.089844 Z M 28.617188 31.089844 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={background} d="M 23.398438 36.445312 C 25.195312 36.019531 27.121094 35.855469 28.714844 36.980469 C 29.535156 37.554688 29.886719 38.386719 30.160156 39.320312 C 30.445312 38.320312 30.859375 37.433594 31.765625 36.867188 C 33.394531 35.871094 35.164062 36.03125 36.941406 36.453125 C 36.414062 38.210938 35.660156 39.941406 33.976562 40.867188 C 32.953125 41.410156 31.933594 41.273438 30.867188 40.972656 C 30.871094 41.964844 30.871094 42.953125 30.871094 43.945312 C 30.402344 43.945312 29.933594 43.945312 29.46875 43.945312 C 29.464844 42.953125 29.464844 41.964844 29.476562 40.972656 C 28.390625 41.269531 27.363281 41.421875 26.328125 40.867188 C 24.664062 39.941406 23.902344 38.191406 23.398438 36.445312 Z M 23.398438 36.445312 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={color} d="M 39.808594 45.855469 C 39.953125 46.15625 40.105469 46.453125 40.25 46.75 C 39.515625 47.109375 38.777344 47.472656 38.039062 47.828125 C 38.199219 48.148438 38.355469 48.472656 38.511719 48.796875 C 39.050781 48.535156 39.589844 48.277344 40.132812 48.019531 C 40.273438 48.316406 40.414062 48.613281 40.558594 48.914062 C 40.027344 49.175781 39.492188 49.4375 38.960938 49.699219 C 39.148438 50.089844 39.335938 50.488281 39.523438 50.882812 C 40.285156 50.503906 41.050781 50.128906 41.816406 49.765625 C 41.964844 50.0625 42.109375 50.355469 42.257812 50.652344 C 41.152344 51.199219 40.039062 51.742188 38.929688 52.28125 C 38.140625 50.664062 37.339844 49.054688 36.5625 47.433594 C 37.648438 46.910156 38.726562 46.378906 39.808594 45.855469 Z M 39.808594 45.855469 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={color} d="M 20.445312 46.128906 C 21.519531 46.546875 22.535156 47.121094 23.582031 47.605469 C 23.433594 47.90625 23.28125 48.203125 23.128906 48.5 C 22.429688 48.160156 21.730469 47.824219 21.03125 47.484375 C 20.878906 47.808594 20.722656 48.132812 20.566406 48.457031 C 21.085938 48.714844 21.609375 48.96875 22.132812 49.222656 C 21.992188 49.519531 21.855469 49.816406 21.714844 50.109375 C 21.1875 49.859375 20.65625 49.609375 20.128906 49.355469 C 19.792969 50.050781 19.453125 50.742188 19.117188 51.433594 C 18.769531 51.265625 18.425781 51.097656 18.082031 50.929688 C 18.886719 49.339844 19.589844 47.691406 20.445312 46.128906 Z M 20.445312 46.128906 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={color} d="M 25.183594 48.101562 C 26.089844 48.253906 27.0625 48.304688 27.933594 48.605469 C 28.828125 48.976562 29.046875 50.035156 28.660156 50.859375 C 28.433594 51.183594 28.039062 51.394531 27.730469 51.640625 C 27.980469 52.441406 28.238281 53.246094 28.492188 54.050781 C 28.066406 53.988281 27.640625 53.921875 27.214844 53.855469 C 27 53.109375 26.78125 52.363281 26.566406 51.613281 C 26.320312 51.574219 26.078125 51.535156 25.835938 51.492188 C 25.730469 52.195312 25.625 52.894531 25.519531 53.601562 C 25.140625 53.542969 24.757812 53.488281 24.371094 53.4375 C 24.640625 51.65625 24.910156 49.882812 25.183594 48.101562 Z M 25.183594 48.101562 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={color} d="M 34.691406 48.066406 C 34.746094 48.394531 34.796875 48.726562 34.851562 49.054688 C 34.039062 49.183594 33.234375 49.3125 32.425781 49.441406 C 32.480469 49.800781 32.539062 50.152344 32.59375 50.511719 C 33.183594 50.414062 33.769531 50.320312 34.363281 50.222656 C 34.414062 50.554688 34.464844 50.878906 34.515625 51.207031 C 33.925781 51.304688 33.34375 51.398438 32.753906 51.492188 C 32.816406 51.921875 32.878906 52.351562 32.941406 52.78125 C 33.78125 52.652344 34.621094 52.519531 35.464844 52.386719 C 35.519531 52.714844 35.578125 53.042969 35.632812 53.371094 C 34.414062 53.574219 33.1875 53.765625 31.964844 53.957031 C 31.6875 52.183594 31.402344 50.410156 31.125 48.632812 C 32.316406 48.441406 33.503906 48.253906 34.691406 48.066406 Z M 34.691406 48.066406 " fill-opacity="1" fill-rule="nonzero"/>
    
    <path fill={background} d="M 26.191406 49.195312 C 26.636719 49.300781 27.125 49.378906 27.539062 49.574219 C 27.734375 49.875 27.714844 50.214844 27.476562 50.59375 C 26.996094 50.6875 26.464844 50.605469 25.972656 50.570312 C 26.046875 50.113281 26.117188 49.65625 26.191406 49.195312 Z M 26.191406 49.195312 " fill-opacity="1" fill-rule="nonzero"/>
    
    </svg>
  );
}

export default SinGlutenIcon;
